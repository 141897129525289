import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  theme => ({
    input: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    root: {
      ...theme.typography.body1,
      lineHeight: 1.9,
      width: "100%",
    },
    textRight: {
      textAlign: "right",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    flexRow: {
      display: "flex",
    },
    flexRowWithGap: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    flexRowCenter: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    centerLoader: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: -1,
    },
    supplierCompany: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    centerFlex: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "50px",
    },
    pointer: {
      cursor: "pointer",
    },
    spaceBetween: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    moneyBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "24px",
    },
    textError: {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(1.5),
      display: "inline",
    },
    subtitle: {
      color: theme.palette.grey[500],
      paddingRight: theme.spacing(1),
    },
    relativeRow: {
      position: "relative",
    },
    percentDiscountLabelContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      justifyContent: "flex-end",
    },
    pagination: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingInline: "32px",
      height: "64px",
    },
    arrow: {
      height: "36px",
      width: "36px",
      border: "2px solid",
      borderColor: theme.palette.grey[500],
    },
    btn_disabled: {
      cursor: "not-allowed",
      opacity: "0.5",
    },
    shippingMethodContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
    },
    lineClamp2: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    flexEnd: {
      display:"flex",
      alignItems: "center",
      justifyContent: "flex-end"
    }
  }),
  { name: "Imports" },
);
