import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  theme => ({
    input: {
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    list: {
      "& td": {
        paddingInline: "12px",
        height: "45px",
      },
      "& th": {
        paddingInline: "12px",
      },
    },
    root: {
      ...theme.typography.body1,
      lineHeight: 1.9,
      width: "100%",
    },
    lineClamp1: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
    select: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      margin: "0 !important",
    },
    selectPosition: {
      top: "-50px",
    },
    flexRow: {
      display: "flex",
    },
    pagination: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingInline: "32px",
      height: "64px",
    },
    arrow: {
      height: "36px",
      width: "36px",
      border: "2px solid",
      borderColor: theme.palette.grey[500],
    },
    btn_disabled: {
      cursor: "not-allowed",
      opacity: "0.5",
    },
    flexRowWithGap: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    scrollModal: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      maxHeight: "60vh",
      overflowY: "auto",
      overflowX: "hidden",
    },
    maxHeight: {
      maxHeight: "unset !important",
    },
  }),
  { name: "Imports" },
);
