import { WindowTitle } from "@dashboard/components/WindowTitle";
import useForm from "@dashboard/hooks/useForm";
import ReportCreateDialog, {
  SelectedProps,
} from "@dashboard/reports/components/ReportCreateDialog/ReportCreateDialog";
import { CircularProgress, Typography } from "@material-ui/core";
import { Box, Button } from "@saleor/macaw-ui-next";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import TypeModal from "./components/TypeModal/TypeModal";

export interface ExcludedReportData {
  products: string[];
  customers: string[];
}
export interface ReportData {
  channels: string[];
  report_type: string;
  products: SelectedProps;
  customers: SelectedProps;
  dates: {
    startDate: string;
    endDate: string;
  };
  excluded: ExcludedReportData;
}

const initialForm: ReportData = {
  report_type: "",
  products: [],
  customers: [],
  channels: [],
  dates: {
    startDate: "",
    endDate: "",
  },
  excluded: {
    products: [],
    customers: [],
  },
};

export const ReportsSection: React.FC = () => {
  const printRef = useRef(null);
  const [showModal, setShowModal] = useState(true);
  const [showSteps, setShowSteps] = useState(false);
  const [report, setReport] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const submitModal = async (data: any) => {
    await fetchData(data);
    // console.log(data)
    // setReport("https://aprior-dev-api.mindnow.dev/reports/");
    setShowModal(false);
  };

  const { change, data, reset, submit } = useForm(
    initialForm,
    submitModal as any,
  );

  const fetchData = async (data: any) => {
    setIsLoading(true);
    setShowSteps(false);
    const resp = await fetch("https://aprior-dev-api.mindnow.dev/reports/", {
      method: "POST",
      body: JSON.stringify({ data }),
    })
      .then(data => data.text())
      .catch(() => {
        setServerError(true);
        return "";
      })
      .finally(() => {
        setIsLoading(false);
      });
    setReport(resp);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: "@page { size: auto; margin: 15mm 10mm; }",
  });

  return (
    <>
      <WindowTitle title={"Reports"} />
      <Box
        display="grid"
        paddingX={10}
        paddingY={10}
        gridTemplateColumns={1}
        style={{ minHeight: "100vh" }}
      >
        <TypeModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => {
            setShowModal(false);
            setShowSteps(true);
          }}
          change={change}
          selected={data.report_type}
        />

        <ReportCreateDialog
          open={showSteps}
          onClose={() => setShowSteps(false)}
          change={change}
          data={data}
          reset={reset}
          submit={submit}
        />

        {isLoading && (
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
          >
            <CircularProgress />
          </Box>
        )}

        {serverError && (
          <Typography color={"error"} variant="caption">
            Something went wrong
          </Typography>
        )}

        {report && (
          <>
            <Box display={"flex"} justifyContent={"flex-end"} marginBottom={4}>
              <Button onClick={handlePrint} variant="secondary">
                Print report
              </Button>
            </Box>

            <div ref={printRef} dangerouslySetInnerHTML={{ __html: report }} />
          </>
        )}
      </Box>
    </>
  );
};
export default ReportsSection;
