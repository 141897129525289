// @ts-strict-ignore
import editorJsColumns from "@calumk/editorjs-columns";
import strikethroughIcon from "@dashboard/icons/StrikethroughIcon";
import EditorJS, { ToolConstructable, ToolSettings } from "@editorjs/editorjs";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import AnyButton from "editorjs-button";
import createGenericInlineTool from "editorjs-inline-tool";

const inlineToolbar = ["link", "bold", "italic", "strikethrough"];
const imageApiUri = process.env.API_URI.replace("graphql", "editorjs");

export const tools: Record<string, ToolConstructable | ToolSettings> = {
  embed: Embed,
  header: {
    class: Header,
    config: {
      defaultLevel: 1,
      levels: [1, 2, 3],
    },
    inlineToolbar,
  },
  image: {
    class: Image,
    config: {
      endpoints: {
        byFile: `${imageApiUri}upload-file/`,
        byUrl: `${imageApiUri}fetch-url/`,
      },
    },
  },
  AnyButton: {
    class: AnyButton,
  },
  columns: {
    class: editorJsColumns,
    config: {
      EditorJsLibrary: EditorJS,
      tools: {
        list: {
          class: List,
        },
        header: {
          class: Header,
          config: {
            defaultLevel: 1,
            levels: [1, 2, 3],
          },
        },
        image: {
          class: Image,
          config: {
            endpoints: {
              byFile: `${imageApiUri}upload-file/`,
              byUrl: `${imageApiUri}fetch-url/`,
            },
          },
        },
        AnyButton: {
          class: AnyButton,
        },
        quote: {
          class: Quote,
        },
        paragraph: {
          class: Paragraph,
        } as unknown as ToolConstructable,
      },
    },
  },
  list: {
    class: List,
    inlineToolbar,
  },
  quote: {
    class: Quote,
    inlineToolbar,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar,
  } as unknown as ToolConstructable,
  strikethrough: createGenericInlineTool({
    sanitize: {
      s: {},
    },
    shortcut: "CMD+S",
    tagName: "s",
    toolboxIcon: strikethroughIcon,
  }),
};
