import { Typography } from "@material-ui/core";
import { Box } from "@saleor/macaw-ui-next";
import React, { useEffect, useState } from "react";

import { useStyles } from "../styles";
import { SupplierType } from "../types";
import { getIndustryText } from "../urls";

interface SupplierInfoProps {
  supplier: SupplierType;
}

export const SupplierInfo: React.FC<SupplierInfoProps> = ({ supplier }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (supplier?.firstName || supplier?.lastName) {
      setName(`${supplier.firstName} ${supplier?.lastName}`);
    }
  }, [supplier?.id]);

  useEffect(() => {
    if (supplier?.city || supplier?.country) {
      setLocation(
        `${supplier.country ? `${supplier.city},` : `${supplier.city}`} ${
          supplier?.country || ""
        }`,
      );
    }
  }, [supplier?.id]);

  return (
    <Box className={classes.supplierCompany} gap={2}>
      <Typography variant="h3" style={{ marginBottom: "10px" }}>
        Transport Company
      </Typography>
      {supplier?.companyName && (
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>Company Name:</Typography>
          <Typography variant="h4">{supplier.companyName}</Typography>
        </Box>
      )}
      {name && (
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>Name:</Typography>
          <Typography variant="h4">{name}</Typography>
        </Box>
      )}
      {supplier?.address && (
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>Address:</Typography>
          <Typography variant="h4">{supplier.address}</Typography>
        </Box>
      )}
      {supplier?.email && (
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>Email:</Typography>
          <Typography variant="h4">{supplier.email}</Typography>
        </Box>
      )}
      {location && (
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>Location:</Typography>
          <Typography variant="h4">{location}</Typography>
        </Box>
      )}
      {supplier?.industry && (
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>Industry:</Typography>
          <Typography variant="h4">
            {getIndustryText(supplier?.industry)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
