import { useQuery } from "@apollo/client";
import useNavigator from "@dashboard/hooks/useNavigator";
import { suppliersList } from "@dashboard/suppliers/queries";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { CreateImportModal } from "../components/CreateImportModal";
import { HeaderNav } from "../components/HeaderNav";
import { ImportsList } from "../components/ImportsList";
import { ImportPagination } from "../components/ImportsTablePagination";
import { importsList } from "../queries";
import { useStyles } from "../styles";
import { importPath, importsSection } from "../urls";

const numberOfRows = [10, 20, 30, 50, 100];

export const Imports = () => {
  const localNoPerRows = localStorage.getItem("importsRows")
        ? parseInt(localStorage.getItem("importsRows") as string, 10)
        : 20;
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [noPerRows, setNoPerRows] = useState(localNoPerRows);

  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const after = urlParams.get("after") || undefined;
  const before = urlParams.get("before") || undefined;
  const last = before ? noPerRows : undefined;
  const first = after ? noPerRows : undefined;

  const { data, loading, refetch } = useQuery(importsList, {
    variables: {
      first: !after && !before ? noPerRows : first,
      after,
      before,
      last,
      sortBy: { direction: "DESC", field: "ID" },
    },
    fetchPolicy: "network-only",
  });

  const { data: suppliersData, refetch: refetchSuppliers } = useQuery(
    suppliersList,
    {
      variables: {
        first: 50,
        filter: { search: "" },
      },
    },
  );

  const classes = useStyles();
  const navigate = useNavigator();

  const paginationPrev = () => {
    const params = new URLSearchParams(search);
    const prevPageId = data.supplyTransactions.pageInfo.startCursor;

    if (urlParams.get("after")) {
      params.delete("after");
    }

    params.set("before", prevPageId);

    history.replace(`${importsSection}?${params.toString()}`);
  };

  const paginationNext = () => {
    const params = new URLSearchParams(search);
    const nextPageId = data.supplyTransactions.pageInfo.endCursor;

    if (urlParams.get("before")) {
      params.delete("before");
    }

    params.set("after", nextPageId);

    history.replace(`${importsSection}?${params.toString()}`);
  };

  const onChangeRows = (value: number) => {
    const params = new URLSearchParams(search);

    if (urlParams.get("after") || urlParams.get("before")) {
      params.delete("after");
      params.delete("before");
    }

    localStorage.setItem("importsRows", value.toString());
    setNoPerRows(value);

    history.replace(`${importsSection}?${params.toString()}`);
  };

  const onSuccess = async (id: string) => {
    await refetch();
    navigate(importPath(id));
  };

  const onSearchSuppliers = async (text: string) => {
    await refetchSuppliers({
      first: 20,
      filter: { search: text },
    });
  };

  return (
    <>
      <HeaderNav onCreate={() => setOpenCreateModal(true)} />
      {loading ? (
        <div className={classes.centerLoader}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <ImportsList imports={data.supplyTransactions.edges} onDeleteSuccess={() => refetch()} />
          <ImportPagination
            importData={data}
            onChangeRows={value => onChangeRows(value)}
            classes={classes}
            noPerRows={noPerRows}
            paginationNext={paginationNext}
            paginationPrev={paginationPrev}
            numberOfRows={numberOfRows}
          />
        </>
      )}
      <CreateImportModal
        onClose={() => setOpenCreateModal(false)}
        open={openCreateModal}
        suppliersList={suppliersData}
        onSuccess={id => onSuccess(id)}
        onSupplierSearch={text => onSearchSuppliers(text)}
      />
    </>
  );
};
