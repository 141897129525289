import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { useChannelsSearch } from "@dashboard/components/ChannelsAvailabilityDialog/utils";
import { FormChange } from "@dashboard/hooks/useForm";
import { ChannelsView } from "@dashboard/reports/components/Channels/Channels";
import React from "react";

interface ReportChannelsProps {
  change: FormChange;
  selected: string[];
}

const ReportChannels: React.FC<ReportChannelsProps> = ({
  change,
  selected,
}) => {
  const { availableChannels } = useAppChannel(false);
  const { filteredChannels } = useChannelsSearch(availableChannels);
  const [selectedChannels, setSelectedChannels] =
    React.useState<string[]>(selected);

  return (
    <ChannelsView
      channels={filteredChannels}
      selectedChannels={selectedChannels}
      setSelectedChannels={setSelectedChannels}
      handleFormData={change}
    />
  );
};

export default ReportChannels;
