import { getIndustryText } from "@dashboard/imports/urls";
import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { DeleteIcon } from "@saleor/macaw-ui";
import { Box, Button, EditIcon, Tooltip } from "@saleor/macaw-ui-next";
import React from "react";

import { useStyles } from "../style";
import { EditSupplierDataType } from "../types";

interface SupplierLineProps {
  supplier: any;
  onEdit: (data: EditSupplierDataType) => void;
  onDelete: (id: string) => void;
}

export const SupplierLine: React.FC<SupplierLineProps> = ({
  supplier,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();

  const {
    id,
    companyName,
    firstName,
    lastName,
    industry,
    email,
    address,
    city,
    country,
    postalCode,
    note,
  } = supplier;

  const cityAndCountry =
    (city || country) && `${country ? `${city},` : `${city}`} ${country || ""}`;
  const firstAndLastName =
    !firstName || !lastName ? "" : `${firstName} ${lastName}`;
  const addressAndPostalCode =
    !address || !postalCode
      ? ""
      : `${postalCode ? `${address}, ` : `${address}`} ${postalCode}`;

  return (
    <TableRow key={id}>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>{companyName}</div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {companyName}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>
                {getIndustryText(industry) || <Skeleton animation={false} />}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {getIndustryText(industry)}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>{firstAndLastName || <Skeleton animation={false} />}</div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {firstAndLastName}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>{email || <Skeleton animation={false} />}</div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {email}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>
                {addressAndPostalCode || <Skeleton animation={false} />}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {addressAndPostalCode}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>{cityAndCountry || <Skeleton animation={false} />}</div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {cityAndCountry}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.lineClamp1}>
          <Tooltip>
            <Tooltip.Trigger>
              <div>{note || <Skeleton animation={false} />}</div>
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow />
              {note}
            </Tooltip.Content>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell>
        <Box className={classes.flexRow}>
          <Button
            variant="tertiary"
            icon={<EditIcon />}
            onClick={() =>
              onEdit({
                id,
                companyName,
                firstName,
                lastName,
                industry,
                email,
                address,
                city,
                country,
                postalCode,
                note,
              })
            }
          />
          <Button
            variant="tertiary"
            icon={<DeleteIcon />}
            onClick={() => onDelete(id)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};
