import TableCellHeader from "@dashboard/components/TableCellHeader";
import { TableBody, TableHead, TableRow } from "@material-ui/core";
import { ResponsiveTable } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui-next";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { useStyles } from "../style";
import { EditSupplierDataType } from "../types";
import { DeleteModal } from "./DeleteModal";
import { SupplierLine } from "./SupplierLine";
import { columnsMessages } from "./SupplierListDatagrid/messages";
import { SupplierModal } from "./SupplierModal";

interface SuppliersListProps {
  suppliers: any;
  onRefetch: () => void;
}

export const SuppliersList: React.FC<SuppliersListProps> = ({
  suppliers,
  onRefetch,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState<EditSupplierDataType>();
  const [deleteSupplierId, setDeleteSupplierId] = useState("");

  const onEditData = (data: any) => {
    setEditData(data);
  };

  return (
    <>
      <ResponsiveTable className={classes.list}>
        <TableHead>
          <TableRow>
            <TableCellHeader width={250}>
              {intl.formatMessage(columnsMessages.companyName)}
            </TableCellHeader>
            <TableCellHeader width={150}>
              {intl.formatMessage(columnsMessages.industry)}
            </TableCellHeader>
            <TableCellHeader width={150}>
              {intl.formatMessage(columnsMessages.name)}
            </TableCellHeader>
            <TableCellHeader>
              {intl.formatMessage(columnsMessages.email)}
            </TableCellHeader>
            <TableCellHeader width={200}>
              {intl.formatMessage(columnsMessages.address)}
            </TableCellHeader>
            <TableCellHeader width={200}>
              {intl.formatMessage(columnsMessages.location)}
            </TableCellHeader>
            <TableCellHeader>
              {intl.formatMessage(columnsMessages.note)}
            </TableCellHeader>
            <TableCellHeader></TableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers?.map(item => {
            return (
              <SupplierLine
                key={item.id}
                supplier={item}
                onEdit={data => {
                  onEditData(data);
                  setEditModal(true);
                }}
                onDelete={id => {
                  setDeleteSupplierId(id);
                }}
              />
            );
          })}
        </TableBody>
      </ResponsiveTable>
      {editModal && (
        <Box>
          <SupplierModal
            key={editData.id}
            onClose={() => setEditModal(false)}
            open={editModal}
            type="edit"
            editData={editData}
            onRefetch={onRefetch}
          />
        </Box>
      )}
      {deleteSupplierId && (
        <DeleteModal
          onClose={() => setDeleteSupplierId("")}
          open={!!deleteSupplierId}
          supplierId={deleteSupplierId}
          supplierName={
            suppliers?.find(sup => sup.id === deleteSupplierId)?.companyName
          }
          onRefetch={onRefetch}
        />
      )}
    </>
  );
};
