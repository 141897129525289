import urljoin from "url-join";

export const importsSection = "/imports";

export const importsListPath = importsSection;

export const importPath = (id: string) => urljoin(importsSection, id);

export const localizePrice = (
  price?: number,
  { decimals = 2, alwaysPositive = false } = {},
) => {
  let newPrice = price ?? 0;
  newPrice = (alwaysPositive ? Math.abs(newPrice) : newPrice) || 0;

  return newPrice.toLocaleString("de-CH", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export const getIndustryText = (text: string) => {
  switch (text) {
    case "WINE_PRODUCER":
      return "Wine producer";
    case "TRANSPORT_AND_SHIPPING":
      return "Transport and shipping";
    case "IMPORTER":
      return "Importer";
    case "CERAMICS":
      return "Ceramics";
    case "WHOLESALER":
      return "Wholesaler";
    case "FOOD_TRADER":
      return "Food trader";
    case "PUBLIC_AUTHORITY":
      return "Public Authority";
    case "WINE_TRADER":
      return "Wine trader";
    case "OTHERS":
      return "Other";
    default:
      return "";
  }
};
