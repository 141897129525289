import { WindowTitle } from "@dashboard/components/WindowTitle";
import { parse as parseQs } from "qs";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import { importPath, importsSection } from "./urls";
import { ImportDetails } from "./views/ImportDetails";
import { Imports } from "./views/Imports";

const ImportDetailsComponent: React.FC<RouteComponentProps<any>> = ({
  location,
  match,
}) => {
  const qs = parseQs(location.search.substr(1)) as any;
  const params: any = qs;
  const id = match.params.id;

  return <ImportDetails id={decodeURIComponent(id)} params={params} />;
};

const ImportComponent = () => (
  <section>
    <WindowTitle title="Imports" />
    <Switch>
      <Route exact path={importsSection} component={Imports} />
      <Route
        exact
        path={importPath(":id")}
        component={ImportDetailsComponent}
      />
    </Switch>
  </section>
);

export default ImportComponent;
