import { useMutation } from "@apollo/client";
import { Divider, Typography } from "@material-ui/core";
import { Button } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui-next";
import React from "react";
import Moment from "react-moment";

import { supplyTransactionImport } from "../mutations";
import { useStyles } from "../styles";
import { SupplyTransactionType } from "../types";
import { localizePrice } from "../urls";
import { SupplierInfo } from "./SupplierInfo";

interface SupplySidebarProps {
  data: SupplyTransactionType;
  onRefetch: () => void;
}

export const SupplySidebar: React.FC<SupplySidebarProps> = ({
  data,
  onRefetch,
}) => {
  const [updateImport] = useMutation(supplyTransactionImport);
  const classes = useStyles();

  const onImport = async () => {
    try {
      await updateImport({
        variables: { id: data.id },
      });
      onRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box className={classes.flexColumn} padding={8}>
      <Typography variant="h2" style={{ marginBottom: "32px" }}>
        Infos
      </Typography>
      <SupplierInfo supplier={data?.supplier} />
      <Divider />
      <Box className={classes.flexColumn} gap={12}>
        <Typography variant="h3">Transport Cost</Typography>
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>CHF</Typography>
          <Typography variant="h4">
            {localizePrice(data?.transportCost)}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.flexColumn} gap={12}>
        <Typography variant="h3">Customs</Typography>
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>CHF</Typography>
          <Typography variant="h4">
            {localizePrice(data?.customsCost)}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.flexColumn} gap={12}>
        <Typography variant="h3">Import VAT</Typography>
        <Box className={classes.flexRow}>
          <Typography className={classes.subtitle}>CHF</Typography>
          <Typography variant="h4">{localizePrice(data?.vatCost)}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box marginTop={6}>
        <Button
          variant="secondary"
          disabled={data?.isImported}
          style={{ marginBottom: "12px" }}
          onClick={() => onImport()}
        >
          Import
        </Button>
        <Typography variant="body2">Import can be done only once.</Typography>
        {data?.importedAt && (
          <Typography
            variant="body2"
            className={classes.flexRow}
            style={{ gap: "5px" }}
          >
            Imported at:
            <b>
              <Moment date={data?.importedAt} format="dddd, MMMM Do, YYYY" />
            </b>
            <Typography variant="body1">
              <Moment
                date={data?.importedAt}
                format="h:mm A"
                style={{ color: "#949494", fontWeight: "bolder" }}
              />
            </Typography>
          </Typography>
        )}
      </Box>
    </Box>
  );
};
