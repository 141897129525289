import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { DetailPageLayout } from "@dashboard/components/Layouts";
import Savebar from "@dashboard/components/Savebar";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { AddProductDialog } from "../components/AddProductDialog";
import { ImportDetailsHeader } from "../components/ImportDetailsHeader";
import { ProductTable } from "../components/ProductTable";
import { SupplySidebar } from "../components/Sidebar";
import { TotalCostSection } from "../components/TotalCostSection";
import { updateSupplyTransaction } from "../mutations";
import { importsList, singleImport } from "../queries";
import { importsSection } from "../urls";

interface ImportDetailsProps {
  id: string;
  params: any;
}

export const ImportDetails: React.FC<ImportDetailsProps> = ({ id }) => {
  const { data, refetch } = useQuery(singleImport, {
    variables: { id },
  });
  const [refetchImports] = useLazyQuery(importsList);

  const [updateTransaction] = useMutation(updateSupplyTransaction);
  const navigate = useNavigator();
  const intl = useIntl();
  const notify = useNotifier();

  const [openProductModal, setOpenProductModal] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!data) return;

    if (data?.supplyTransaction?.lines?.length !== 0) {
      for (const line of data?.supplyTransaction?.lines) {
        if (!line.unitPrice) {
          return setDisableSave(true);
        } else {
          setDisableSave(false);
        }
      }
    }
  }, [JSON.stringify(data?.supplyTransaction?.lines)]);

  const onSave = async () => {
    const input = {
      supplier: data?.supplyTransaction?.supplier?.id,
      transportCost: data?.supplyTransaction?.transportCost,
      customCost: data?.supplyTransaction?.customCost,
      vatCost: data?.supplyTransaction?.vatCost,
    };
    try {
      const resp = await updateTransaction({
        variables: { id, input },
      });

      if (resp?.data?.supplyTransactionUpdate?.errors?.length === 0) {
        notify({
          status: "success",
          text: "Changes saved.",
        });
      }
      await refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const onRefetch = async () => {
    await refetch();
    const noPerRows = localStorage.getItem("importsRows");
    await refetchImports({ variables: { first: noPerRows } });
  };

  return (
    <DetailPageLayout>
      <ImportDetailsHeader
        importNumber={data?.supplyTransaction?.number}
        createdAt={data?.supplyTransaction?.createdAt}
      />
      <DetailPageLayout.Content>
        <ProductTable
          lines={data?.supplyTransaction?.lines}
          onAddProduct={() => setOpenProductModal(true)}
          onRefetch={onRefetch}
          isImported={data?.supplyTransaction?.isImported}
        />
        <TotalCostSection data={data?.supplyTransaction} />
      </DetailPageLayout.Content>
      <DetailPageLayout.RightSidebar>
        <SupplySidebar data={data?.supplyTransaction} onRefetch={onRefetch} />
      </DetailPageLayout.RightSidebar>
      <Savebar
        state={"default"}
        disabled={disableSave}
        onCancel={() => navigate(importsSection)}
        onSubmit={() => onSave()}
        labels={{
          confirm: intl.formatMessage({
            id: "RaycYK",
            defaultMessage: "Save",
            description: "button",
          }),
        }}
      />
      {openProductModal && (
        <AddProductDialog
          onClose={() => setOpenProductModal(false)}
          open={openProductModal}
          supplyId={data?.supplyTransaction?.id}
          onSuccess={onRefetch}
        />
      )}
    </DetailPageLayout>
  );
};
