import { useMutation } from "@apollo/client";
import ActionDialog from "@dashboard/components/ActionDialog";
import useNavigator from "@dashboard/hooks/useNavigator";
import useNotifier from "@dashboard/hooks/useNotifier";
import { DuplicateProduct } from "@dashboard/products/mutations";
import { Input } from "@saleor/macaw-ui-next";
import React, { useEffect, useState } from "react";

import { productUrl } from "../../urls";

interface DuplicateProductDialogProps {
  open: boolean;
  onClose: () => void;
  product: any;
}

const DuplicateProductDialog: React.FC<DuplicateProductDialogProps> = ({
  onClose,
  open,
  product,
}) => {
  const [duplicateMutation] = useMutation(DuplicateProduct);
  const [productName, setProductName] = useState("");

  const [error, setError] = useState("");

  const navigate = useNavigator();
  const notify = useNotifier();

  useEffect(() => {
    if (product && product.name) {
      setProductName(`COPY - ${product?.name}`);
    }
  }, [product]);

  const onConfirm = async () => {
    try {
      const { data } = await duplicateMutation({
        variables: {
          name: productName,
          id: product.id,
        },
      });

      if (data?.productDuplicate?.errors?.length !== 0) {
        setError(data.productDuplicate.errors[0].message);
      }

      if (data?.productDuplicate?.errors?.length === 0) {
        const productId = data.productDuplicate.product.id;
        onClose();
        notify({
          status: "success",
          text: "Product copied successfully",
        });
        navigate(productUrl(productId));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onConfirm()}
      title="Duplicate product"
      confirmButtonState="default"
      maxWidth="sm"
    >
      <Input
        label={"Copy Product name"}
        size="medium"
        value={productName}
        onChange={e => setProductName(e.target.value)}
        name="name"
      />
      {error && <div style={{ color: "red", marginTop: "12px" }}>{error}</div>}
    </ActionDialog>
  );
};

DuplicateProductDialog.displayName = "DuplicateProductDialog";
export default DuplicateProductDialog;
