// @ts-strict-ignore
import makeCreatorSteps, { Step } from "@dashboard/components/CreatorSteps";
import { FormChange } from "@dashboard/hooks/useForm";
import useModalDialogOpen from "@dashboard/hooks/useModalDialogOpen";
import useWizard from "@dashboard/hooks/useWizard";
import { ReportData } from "@dashboard/reports";
import { DialogButton } from "@dashboard/reports/components/DialogButton/DialogButton";
import ReportDate from "@dashboard/reports/components/ReportCreateDialog/steps/ReportDate";
import { reportTypes } from "@dashboard/reports/components/ReportCreateDialog/steps/ReportType";
import { DialogProps } from "@dashboard/types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { productExportDialogMessages as messages } from "./messages";
import ReportChannels from "./steps/ReportChannels";
import ReportCustomers from "./steps/ReportCustomers";
import ReportProducts from "./steps/ReportProducts";
import ReportType from "./steps/ReportType";

export enum ReportCreateStep {
  TYPE = 0,
  CHANNELS = 1,
  CUSTOMERS = 2,
  PRODUCTS = 3,
  DATES = 4,
}

export type SelectedProps = string[] | "all";

function useSteps(steps): Array<Step<ReportCreateStep>> {
  const intl = useIntl();

  const stepsData = [
    {
      label: intl.formatMessage({
        id: "D/v7Vw",
        defaultMessage: "Type",
        description: "product export to csv file, header",
      }),
      value: ReportCreateStep.TYPE,
    },
    {
      label: intl.formatMessage({
        id: "wBMprp",
        defaultMessage: "Channels",
        description: "product export to csv file, header",
      }),
      value: ReportCreateStep.CHANNELS,
    },
    {
      label: intl.formatMessage({
        id: "1VUDcc",
        defaultMessage: "Customers",
        description: "product export to csv file, header",
      }),
      value: ReportCreateStep.CUSTOMERS,
    },
    {
      label: intl.formatMessage({
        id: "6W5NsH",
        defaultMessage: "Products",
        description: "product export to csv file, header",
      }),
      value: ReportCreateStep.PRODUCTS,
    },
    {
      label: intl.formatMessage({
        id: "vBduja",
        defaultMessage: "Date",
        description: "product export to csv file, header",
      }),
      value: ReportCreateStep.DATES,
    },
  ];

  return steps.map(i => stepsData.find(s => s.value === i));
}

const ReportCreateSteps = makeCreatorSteps<ReportCreateStep>();

export interface ReportCreateDialogProps extends DialogProps {
  change: FormChange;
  data: ReportData;
  reset: () => void;
  submit: () => void;
}

const ReportCreateDialog: React.FC<ReportCreateDialogProps> = ({
  onClose,
  open,
  change,
  data,
  reset,
  submit,
}) => {
  const reportSteps = reportTypes.find(
    item => item.id === data.report_type,
  )?.steps;

  const [tabs, setTabs] = useState([0]);
  const [step, { next, prev, set: setStep }] = useWizard(tabs[0], tabs);
  const steps = useSteps(tabs);
  const currentStepIndex = tabs.findIndex(i => i === step);

  useModalDialogOpen(open, {
    onClose: () => {
      reset();
      setStep(tabs[0]);
    },
  });

  useEffect(() => {
    if (!reportSteps) return;
    setTabs(reportSteps?.map(step => ReportCreateStep[step.toUpperCase()]));
  }, [reportSteps]);

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <>
        <DialogTitle disableTypography>
          <FormattedMessage {...messages.title} />
        </DialogTitle>
        <DialogContent>
          <ReportCreateSteps
            currentStep={step}
            steps={steps}
            onStepClick={setStep}
          />
          {
            {
              [ReportCreateStep.TYPE]: (
                <ReportType onChange={change} selected={data.report_type} />
              ),
              [ReportCreateStep.CHANNELS]: (
                <ReportChannels change={change} selected={data.channels} />
              ),
              [ReportCreateStep.CUSTOMERS]: (
                <ReportCustomers
                  onChange={change}
                  channels={data.channels}
                  selected={data.customers}
                  excludedData={data.excluded}
                />
              ),
              [ReportCreateStep.PRODUCTS]: (
                <ReportProducts
                  onChange={change}
                  channels={data.channels}
                  selected={data.products}
                  excludedData={data.excluded}
                />
              ),
              [ReportCreateStep.DATES]: (
                <ReportDate onChange={change} selected={data.dates} />
              ),
            }[step]
          }
        </DialogContent>

        <DialogActions>
          <>
            {currentStepIndex !== 0 && (
              <DialogButton type={"prev"} prev={prev} />
            )}
            {currentStepIndex === 0 && (
              <DialogButton type={"cancel"} onClose={onClose} />
            )}
            {currentStepIndex === tabs.length - 1 ? (
              <DialogButton type={"finish"} submit={submit} />
            ) : (
              <DialogButton type={"next"} next={next} />
            )}
          </>
        </DialogActions>
      </>
    </Dialog>
  );
};

ReportCreateDialog.displayName = "ReportCreateDialog";
export default ReportCreateDialog;
