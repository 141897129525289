import { useProductListQuery } from "@dashboard/graphql";
import { FormChange } from "@dashboard/hooks/useForm";
import { ExcludedReportData } from "@dashboard/reports";
import Products from "@dashboard/reports/components/Products/Products";
import { SelectedProps } from "@dashboard/reports/components/ReportCreateDialog/ReportCreateDialog";
import React, { useState } from "react";

interface ReportProductsProps {
  onChange: FormChange;
  channels: string[];
  selected: SelectedProps;
  excludedData: ExcludedReportData;
}

const ReportProducts: React.FC<ReportProductsProps> = ({
  onChange,
  selected,
  channels,
  excludedData,
}) => {
  const [selectedProductsIds, setSelectedProductsIds] =
    React.useState<SelectedProps>(selected);
  const [excluded, setExcluded] = useState<ExcludedReportData>(excludedData);

  const queryParams = {
    first: 20,
    where: {},
    search: undefined,
    filter: {
      channels,
    },
    sort: {
      direction: "ASC",
      field: "NAME",
    },
  } as any;

  const [params, setParams] = useState(queryParams);

  const { data, loading, refetch } = useProductListQuery({
    displayLoader: true,
    variables: {
      ...params,
      hasChannel: true,
      filter: { isWine: true },
    },
    skip: false,
  });

  const handleNext = () => {
    setParams({
      ...params,
      after: data?.products?.pageInfo.endCursor,
      first: 20,
      before: undefined,
      last: undefined,
    });
    refetch();
  };

  const handlePrev = () => {
    setParams({
      ...params,
      after: undefined,
      first: undefined,
      last: 20,
      before: data?.products?.pageInfo.startCursor,
    });
    refetch();
  };

  const handleSearch = (e: string) => {
    setSelectedProductsIds([]);
    setParams({
      ...params,
      search: e,
      after: undefined,
      before: undefined,
      first: 20,
      last: undefined,
    });
  };

  return (
    <Products
      data={data}
      loading={loading}
      handleSearchChange={handleSearch}
      initialSearch={params?.search || ""}
      selectedProductsIds={selectedProductsIds}
      setSelectedProductsIds={setSelectedProductsIds}
      handleFormData={onChange}
      excluded={excluded}
      setExcluded={setExcluded}
      pagination={{
        next: handleNext,
        prev: handlePrev,
      }}
    />
  );
};

export default ReportProducts;
