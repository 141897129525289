import { ControlledCheckbox } from "@dashboard/components/ControlledCheckbox";
import Hr from "@dashboard/components/Hr";
import { ProductListQuery } from "@dashboard/graphql";
import { FormChange } from "@dashboard/hooks/useForm";
import Label from "@dashboard/orders/components/OrderHistory/Label";
import { ExcludedReportData } from "@dashboard/reports";
import ChannelDots from "@dashboard/reports/components/ChannelDots/ChannelDots";
import { SelectedProps } from "@dashboard/reports/components/ReportCreateDialog/ReportCreateDialog";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import {
  CircularProgress,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { PaginationActions } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui-next";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";

interface ProductsProps {
  initialSearch: string;
  loading: boolean;
  handleSearchChange: (e: string) => void;
  data: ProductListQuery | undefined;
  selectedProductsIds: SelectedProps;
  setSelectedProductsIds: React.Dispatch<React.SetStateAction<SelectedProps>>;
  handleFormData: FormChange;
  pagination: {
    next: () => void;
    prev: () => void;
  };
  excluded: ExcludedReportData;
  setExcluded: React.Dispatch<React.SetStateAction<ExcludedReportData>>;
}

export const Products: React.FC<ProductsProps> = ({
  handleSearchChange,
  initialSearch,
  data,
  loading,
  selectedProductsIds,
  setSelectedProductsIds,
  handleFormData,
  pagination,
  excluded,
  setExcluded,
}) => {
  const intl = useIntl();
  const products = mapEdgesToItems(data?.products);
  const isAllSelected = selectedProductsIds === "all";
  // !!products?.length && selectedProductsIds.length === products?.length;

  const handleSelectedProducts = (product: any) => {
    if (!isAllSelected) {
      const selected =
        typeof selectedProductsIds === "string" ? [] : selectedProductsIds;
      const productsData = selected.includes(product.id)
        ? [...selected.filter((id: string) => id !== product.id)]
        : [...selected, product.id];
      setSelectedProductsIds(productsData);
    } else {
      const newExcludedProducts = excluded.products.includes(product.id)
        ? [...excluded.products.filter(id => id !== product.id)]
        : [...excluded.products, product.id];
      setExcluded({ ...excluded, products: newExcludedProducts });
    }
  };

  const handleSelectAllProducts = () => {
    if (!products) return;
    setSelectedProductsIds(isAllSelected ? [] : "all");
    setExcluded({ ...excluded, products: [] });
  };

  useEffect(() => {
    handleFormData({
      target: { name: "products", value: selectedProductsIds },
    });
  }, [selectedProductsIds]);

  useEffect(() => {
    handleFormData({
      target: { name: "excluded", value: excluded },
    });
  }, [excluded]);

  return (
    <>
      <Box paddingY={6}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <ControlledCheckbox
            checked={isAllSelected}
            name="allChannels"
            label={
              <Label
                text={intl.formatMessage({
                  id: "PKIgpZ",
                  defaultMessage: "Select All Products",
                })}
              />
            }
            onChange={handleSelectAllProducts}
          />
          {data?.products && (
            <Typography>
              Selected:{" "}
              {isAllSelected
                ? isAllSelected &&
                  !excluded.products.length &&
                  data.products.totalCount
                  ? data.products.totalCount
                  : data.products.totalCount! - excluded.products.length
                : selectedProductsIds.length}{" "}
              / {data.products.totalCount}
            </Typography>
          )}
        </Box>

        <TextField
          name="query"
          value={initialSearch}
          onChange={e => handleSearchChange(e.target.value)}
          label={intl.formatMessage({
            id: "AHOQr2",
            defaultMessage: "Search products...",
          })}
          placeholder={intl.formatMessage({
            id: "AHOQr2",
            defaultMessage: "Search products...",
          })}
          fullWidth
        />
      </Box>

      <>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            width={"100%"}
            marginY={9}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Hr />
            <Box maxHeight={48} overflowY={"auto"}>
              <List>
                {products?.map(item => {
                  return (
                    <ListItem key={item.id} divider disableGutters>
                      <ControlledCheckbox
                        checked={
                          (isAllSelected &&
                            !excluded.products.includes(item.id)) ||
                          selectedProductsIds.includes(item.id)
                        }
                        name={item.id}
                        label={
                          <Box display={"flex"}>
                            <Typography>{item.name}</Typography>
                            <ChannelDots
                              channels={
                                item.channelListings
                                  ? item.channelListings.map(
                                      i => i.channel.name,
                                    )
                                  : null
                              }
                            />
                          </Box>
                        }
                        onChange={() => handleSelectedProducts(item as any)}
                      />
                    </ListItem>
                  );
                })}
              </List>
              {data?.products && (
                <Box
                  paddingTop={7}
                  paddingBottom={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <PaginationActions
                    hasNextPage={data.products.pageInfo.hasNextPage}
                    hasPreviousPage={data.products.pageInfo.hasPreviousPage}
                    onNextPage={pagination.next}
                    onPreviousPage={pagination.prev}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </>
    </>
  );
};

export default Products;
