import { TopNav } from "@dashboard/components/AppLayout";
import SearchInput from "@dashboard/components/AppLayout/ListFilters/components/SearchInput";
import { sectionNames } from "@dashboard/intl";
import { Suppliers } from "@dashboard/suppliers/types";
import { SupplierListUrlSortField } from "@dashboard/suppliers/urls";
import { PageListProps, SortPage } from "@dashboard/types";
import { Button } from "@saleor/macaw-ui";
import { Box, ChevronRightIcon, Text } from "@saleor/macaw-ui-next";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { useStyles } from "../../style";
import { SupplierModal } from "../SupplierModal";
import { SuppliersList } from "../SuppliersList";
import { SuppliersPagination } from "../SuppliersPagination";

export interface SupplierListPageProps
  extends PageListProps,
    SortPage<SupplierListUrlSortField> {
  loading: boolean;
  suppliers: Suppliers | undefined;
  onRefetch: () => void;
  initialSearch: string;
  onSearchChange: (value: string) => void;
  onChangeRows: (value: number) => void;
  noPerRows: number;
  paginationNext: () => void;
  paginationPrev: () => void;
  numberOfRows: number[];
  paginationData: any;
}

const SupplierListPage: React.FC<SupplierListPageProps> = ({
  suppliers,
  paginationData,
  loading,
  onRefetch,
  initialSearch,
  noPerRows,
  paginationNext,
  onChangeRows,
  paginationPrev,
  numberOfRows,
  onSearchChange,
  ...supplierListProps
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [addSupplierModal, setAddSupplierModal] = useState(false);

  return (
    <>
      <TopNav
        title={intl.formatMessage(sectionNames.suppliers)}
        withoutBorder
        isAlignToRight={false}
      >
        <Box
          __flex={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box marginX={5} display="flex" alignItems="center">
              <ChevronRightIcon />
            </Box>
            <Text variant="title" size="small">
              {intl.formatMessage({
                id: "2MyGo/",
                defaultMessage: "All suppliers",
                description: "tab name",
              })}
            </Text>
          </Box>
          <Button
            onClick={() => setAddSupplierModal(true)}
            type="button"
            variant="primary"
          >
            Create Supplier
          </Button>
        </Box>
      </TopNav>
      <Box __width="320px" padding={4} paddingLeft={8}>
        <SearchInput
          initialSearch={initialSearch}
          placeholder="Search suppliers"
          onSearchChange={value => onSearchChange(value)}
        />
      </Box>
      <Box>
        <SuppliersList suppliers={suppliers} onRefetch={onRefetch} />
        {/* <SupplierListDatagrid
          suppliers={suppliers}
          loading={loading}
          {...supplierListProps}
        /> */}
        <SuppliersPagination
          suppliersData={paginationData}
          onChangeRows={value => onChangeRows(value)}
          classes={classes}
          noPerRows={noPerRows}
          paginationNext={paginationNext}
          paginationPrev={paginationPrev}
          numberOfRows={numberOfRows}
        />
      </Box>
      {addSupplierModal && (
        <SupplierModal
          onClose={() => setAddSupplierModal(false)}
          open={addSupplierModal}
          onRefetch={onRefetch}
          type="add"
        />
      )}
    </>
  );
};

SupplierListPage.displayName = "CustomerListPage";
export default SupplierListPage;
