import { Box } from "@saleor/macaw-ui-next";
import React from "react";

const stringToColour = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

const Dot = ({ name }: { name: string }) => {
  return (
    <Box
      width={2}
      height={2}
      borderRadius="50%"
      title={name}
      __backgroundColor={stringToColour(name)}
    />
  );
};

const ChannelsDots = ({ channels }: { channels: string[] | null }) => {
  return channels ? (
    <Box display={"flex"} gap={1} marginLeft={2}>
      {channels.map((e: string) => (
        <Dot name={e} key={e} />
      ))}
    </Box>
  ) : null;
};

export default ChannelsDots;
