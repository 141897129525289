import { gql } from "@apollo/client";

export const importsList = gql`
  query ImportsList(
    $filter: SupplyTransactionFilterInput
    $sortBy: SupplyTransactionSortingInput
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    supplyTransactions(
      filter: $filter
      sortBy: $sortBy
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        node {
          id
          supplier {
            firstName
            lastName
            address
            email
            country
            companyName
          }
          totalCost
          transportCost
          customsCost
          vatCost
          createdAt
          number
          totalQuantity
          isImported
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const singleImport = gql`
  query singleImport($id: ID!) {
    supplyTransaction(id: $id) {
      number
      id
      isImported
      importedAt
      subtotalCost
      supplier {
        companyName
        id
        email
        firstName
        lastName
        address
        city
        country
        industry
      }
      lines {
        id
        productVariant {
          name
          id
          product {
            name
          }
          pricing {
            price {
              net {
                amount
                currency
              }
            }
            priceUndiscounted {
              net {
                amount
                currency
              }
            }
          }
        }
        quantity
        unitPrice
      }
      totalCost
      transportCost
      customsCost
      vatCost
      createdAt
    }
  }
`;
