import {
  ActiveTab,
  BulkAction,
  Filters,
  Pagination,
  Sort,
} from "@dashboard/types";
import { stringifyQs } from "@dashboard/utils/urls";

export const suppliersSection = "/suppliers/";

export const suppliersListPath = suppliersSection;

export enum SupplierListUrlSortField {
  name = "name",
  email = "email",
  companyName = "companyName",
  industry = "industry",
}
export enum SupplierListUrlFiltersEnum {
  query = "query",
}
export type SupplierListUrlSort = Sort<SupplierListUrlSortField>;
export type SupplierListUrlFilters = Filters<SupplierListUrlFiltersEnum>;

export type SupplierListUrlQueryParams = ActiveTab &
  BulkAction &
  SupplierListUrlFilters &
  SupplierListUrlSort &
  Pagination;

export const supplierListUrl = (params?: SupplierListUrlQueryParams) =>
  suppliersListPath + "?" + stringifyQs(params);

export const IndustryList = [
  {
    label: "Wine producer",
    value: "WINE_PRODUCER",
  },
  {
    label: "Transport and shipping",
    value: "TRANSPORT_AND_SHIPPING",
  },
  {
    label: "Importer",
    value: "IMPORTER",
  },
  {
    label: "Ceramics",
    value: "CERAMICS",
  },
  {
    label: "Wholesaler",
    value: "WHOLESALER",
  },
  {
    label: "Food trader",
    value: "FOOD_TRADER",
  },
  {
    label: "Public authority",
    value: "PUBLIC_AUTHORITY",
  },
  {
    label: "Wine trader",
    value: "WINE_TRADER",
  },
  {
    label: "Other",
    value: "OTHERS",
  },
];
