import { TopNav } from "@dashboard/components/AppLayout";
import { Typography } from "@material-ui/core";
import { Box } from "@saleor/macaw-ui-next";
import React from "react";
import Moment from "react-moment";

import { importsSection } from "../urls";

interface ImportDetailsHeaderProps {
  importNumber: number;
  createdAt: string;
}

export const ImportDetailsHeader: React.FC<ImportDetailsHeaderProps> = ({
  importNumber,
  createdAt,
}) => {
  return (
    <TopNav
      title={`Import #${importNumber}`}
      withoutBorder
      isAlignToRight={false}
      href={importsSection}
    >
      <Box
        __flex={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          gap={5}
          marginLeft={10}
        >
          <Typography variant="body1">
            <Moment date={createdAt} format="dddd, MMMM Do, YYYY" />
          </Typography>
          <Typography variant="body1">
            <Moment
              date={createdAt}
              format="h:mm A"
              style={{ color: "#949494", fontWeight: "bolder" }}
            />
          </Typography>
        </Box>
      </Box>
    </TopNav>
  );
};
