import { useMutation, useQuery } from "@apollo/client";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import SingleAutocompleteSelectField from "@dashboard/components/SingleAutocompleteSelectField";
import useNotifier from "@dashboard/hooks/useNotifier";
import { updateProductSupplier } from "@dashboard/products/mutations";
import { productSupplierAndManufacturer } from "@dashboard/products/queries";
import { suppliersList } from "@dashboard/suppliers/queries";
import React, { useState } from "react";

import { parsedSuppliersType } from "./SupplierManufacturer";

interface SuppliersDropdownProps {
  productId: string;
}

export const SuppliersDropdown: React.FC<SuppliersDropdownProps> = ({
  productId,
}) => {
  const [supplierName, setSupplierName] = useState("");
  const [supplierChoiceId, setSupplierChoiceId] = useState("");
  const [parsedSuppliers, setParsedSuppliers] = useState<parsedSuppliersType[]>(
    [],
  );

  const notify = useNotifier();
  const { channel } = useAppChannel(false);

  const [updateSupplier, { loading }] = useMutation(updateProductSupplier);

  const { data } = useQuery(productSupplierAndManufacturer, {
    variables: {
      id: productId,
      channel: channel.slug,
    },
    fetchPolicy: "network-only",
    displayName: "SuppliersDropdown",
    onCompleted: result => {
      setSupplierChoiceId(result?.product?.supplier?.id ?? "");
      setSupplierName(result?.product?.supplier?.companyName ?? "");
    },
  });

  const { data: suppliersData, refetch: refetchSuppliers } = useQuery(
    suppliersList,
    {
      variables: {
        first: 10,
        filter: { search: "" },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: _ => {
        const parseSuppliers = suppliersData?.suppliers?.edges.map(supplier => {
          return { label: supplier.node.companyName, value: supplier.node.id };
        });
        setParsedSuppliers(parseSuppliers);
      },
    },
  );

  const onSelectSupplier = async (id: string) => {
    setSupplierChoiceId(id);
    const supplierLabel = parsedSuppliers.find(
      supplier => supplier.value === id,
    )?.label;
    setSupplierName(supplierLabel);

    try {
      const { data } = await updateSupplier({
        variables: {
          id: productId,
          supplier: id,
        },
      });

      if (data?.productUpdate?.errors?.length === 0) {
        notify({
          status: "success",
          text: "Saved changes",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearchSuppliers = async (text: string) => {
    await refetchSuppliers({
      first: 50,
      filter: { search: text },
    });
  };

  return (
    <SingleAutocompleteSelectField
      displayValue={supplierName}
      name="suppliers"
      label="Supplier"
      placeholder="Type to search"
      choices={
        suppliersData?.suppliers?.edges.map((supplier: any) => {
          return {
            label: supplier.node.companyName,
            value: supplier.node.id,
          };
        }) ?? []
      }
      value={supplierChoiceId}
      onChange={e => onSelectSupplier(e.target.value)}
      InputProps={{
        required: true,
      }}
      fetchChoices={value => onSearchSuppliers(value)}
      disabled={!data?.product || loading}
    />
  );
};
