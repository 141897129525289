import { gql } from "@apollo/client";

export const supplierCreate = gql`
  mutation supplierCreate($input: SupplierCreateInput!) {
    supplierCreate(input: $input) {
      errors {
        code
        field
        message
      }
      supplier {
        id
      }
    }
  }
`;

export const supplierUpdate = gql`
  mutation supplierUpdate($id: ID!, $input: SupplierUpdateInput!) {
    supplierUpdate(input: $input, id: $id) {
      errors {
        field
        message
        code
      }
      supplier {
        id
      }
    }
  }
`;

export const supplierDelete = gql`
  mutation supplierDelete($id: ID!) {
    supplierDelete(id: $id) {
      errors {
        field
        code
        message
      }
      supplier {
        id
      }
    }
  }
`;
