import { TableCell, TableRow, Typography } from "@material-ui/core";
import {DeleteIcon, EditIcon} from "@saleor/macaw-ui";
import { Box, Button, Tooltip } from "@saleor/macaw-ui-next";
import React from "react";

import { useStyles } from "../styles";
import {DeleteProductType, EditProductType, SupplyTransactionLineType} from "../types";
import { localizePrice } from "../urls";

interface ProductLineProps {
  line: SupplyTransactionLineType;
  price: any;
  onEdit: (lineData: EditProductType) => void;
  onDelete: (lineData: DeleteProductType) => void;
  isImported: boolean;
}

export const ProductLine: React.FC<ProductLineProps> = ({
  price,
  line,
  onEdit,
  onDelete,
  isImported,
}) => {
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell>
          <div className={classes.lineClamp2}>
            <Tooltip>
              <Tooltip.Trigger>
                <div>{line.productVariant?.product?.name}</div>
              </Tooltip.Trigger>
              <Tooltip.Content side="top">
                <Tooltip.Arrow />
                {line.productVariant?.product?.name}
              </Tooltip.Content>
            </Tooltip>
          </div>
        </TableCell>
        <TableCell>{line.productVariant?.name}</TableCell>
        <TableCell>{line.quantity}</TableCell>
        <TableCell>
          {line.unitPrice && (
            <Box className={classes.flexRow}>
              <Typography className={classes.subtitle}>
                {price?.price?.net?.currency}
              </Typography>
              <Typography variant="body2">
                {localizePrice(line.unitPrice)}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <Box className={classes.flexRow}>
            <Typography className={classes.subtitle}>
              {price?.price?.net?.currency}
            </Typography>
            <Typography variant="body2">
              {localizePrice(line.unitPrice * line.quantity)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="right">
          {!isImported && (
            <Box className={classes.flexRow} justifyContent="flex-end" gap={2}>
              <Button
                variant="tertiary"
                icon={<EditIcon />}
                onClick={() =>
                  onEdit({
                    id: line.id,
                    quantity: line.quantity,
                    unitPrice: line.unitPrice,
                  })
                }
              />
              <Button
                variant="tertiary"
                icon={<DeleteIcon />}
                onClick={() => onDelete({ id:line.id, name: line.productVariant?.product?.name })}
              />
            </Box>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
