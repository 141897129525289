import { useMutation, useQuery } from "@apollo/client";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import SingleAutocompleteSelectField from "@dashboard/components/SingleAutocompleteSelectField";
import useNotifier from "@dashboard/hooks/useNotifier";
import { updateProductManufacturer } from "@dashboard/products/mutations";
import { productSupplierAndManufacturer } from "@dashboard/products/queries";
import { suppliersList } from "@dashboard/suppliers/queries";
import React, { useState } from "react";

import { parsedSuppliersType } from "./SupplierManufacturer";

interface ManufacturerDropdownProps {
  productId: string;
}

export const ManufacturerDropdown: React.FC<ManufacturerDropdownProps> = ({
  productId,
}) => {
  const [manufacturerName, setManufacturerName] = useState("");
  const [manufacturerChoiceId, setManufacturerChoiceId] = useState("");

  const [parsedSuppliers, setParsedSuppliers] = useState<parsedSuppliersType[]>(
    [],
  );

  const notify = useNotifier();

  const { channel } = useAppChannel(false);

  const [updateManufacturer, { loading }] = useMutation(
    updateProductManufacturer,
  );

  const { data } = useQuery(productSupplierAndManufacturer, {
    variables: {
      id: productId,
      channel: channel.slug,
    },
    fetchPolicy: "network-only",
    displayName: "ManufacturerDropdown",
    onCompleted: result => {
      setManufacturerChoiceId(result?.product?.manufacturer?.id ?? "");
      setManufacturerName(result?.product?.manufacturer?.companyName ?? "");
    },
  });

  const { data: suppliersData, refetch: refetchSuppliers } = useQuery(
    suppliersList,
    {
      variables: {
        first: 20,
        filter: { search: "" },
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: _ => {
        const parseSuppliers = suppliersData?.suppliers?.edges.map(supplier => {
          return { label: supplier.node.companyName, value: supplier.node.id };
        });
        setParsedSuppliers(parseSuppliers);
      },
    },
  );

  const onSelectManufacturer = async (id: string) => {
    setManufacturerChoiceId(id);
    const manufacturerLabel = parsedSuppliers.find(
      supplier => supplier.value === id,
    )?.label;
    setManufacturerName(manufacturerLabel as string);

    try {
      const { data } = await updateManufacturer({
        variables: {
          id: productId,
          manufacturer: id,
        },
      });

      if (data?.productUpdate?.errors?.length === 0) {
        notify({
          status: "success",
          text: "Saved changes",
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onSearchSuppliers = async (text: string) => {
    await refetchSuppliers({
      first: 50,
      filter: { search: text },
    });
  };

  return (
    <SingleAutocompleteSelectField
      displayValue={manufacturerName}
      name="manufacturers"
      label="Transport"
      placeholder="Type to search"
      choices={parsedSuppliers ?? []}
      value={manufacturerChoiceId}
      onChange={e => onSelectManufacturer(e.target.value)}
      InputProps={{
        required: true,
      }}
      fetchChoices={value => onSearchSuppliers(value)}
      disabled={!data?.product || loading}
    />
  );
};
