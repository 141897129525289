import { useMutation } from "@apollo/client";
import ActionDialog from "@dashboard/components/ActionDialog";
import useNotifier from "@dashboard/hooks/useNotifier";
import { Typography } from "@material-ui/core";
import React from "react";

import { supplierDelete } from "../mutations";

interface DeleteModalProps {
  onClose: () => void;
  open: boolean;
  onRefetch: () => void;
  supplierId: string;
  supplierName: string;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
  onClose,
  onRefetch,
  open,
  supplierId,
  supplierName,
}) => {
  const [deleteMutation] = useMutation(supplierDelete);

  const notify = useNotifier();

  const onDelete = async () => {
    try {
      const { data } = await deleteMutation({
        variables: { id: supplierId },
      });

      if (data?.supplierDelete?.errors?.length === 0) {
        notify({
          status: "success",
          text: `${supplierName} has been deleted successfully.`,
        });
      }
      onClose();
      onRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onDelete()}
      title="Delete Supplier"
      confirmButtonState="default"
      maxWidth="sm"
      confirmButtonLabel="Delete"
      backButtonText="Cancel"
    >
      <Typography variant="body1">
        Are you sure you want to delete <b>{supplierName}</b> from the list?
      </Typography>
    </ActionDialog>
  );
};
