import { Button } from "@dashboard/components/Button";
import { buttonMessages } from "@dashboard/intl";
import React from "react";
import { FormattedMessage } from "react-intl";

interface DialogButtonProps {
  type: "next" | "prev" | "cancel" | "finish";
  next?: () => void;
  prev?: () => void;
  onClose?: () => void;
  submit?: () => void;
}

export const DialogButton: React.FC<DialogButtonProps> = ({
  type,
  next,
  prev,
  onClose,
  submit,
}) => {
  switch (type) {
    case "next":
      return (
        <Button variant="primary" onClick={next} data-test-id="next">
          <FormattedMessage {...buttonMessages.nextStep} />
        </Button>
      );
    case "prev":
      return (
        <Button
          variant="secondary"
          color="text"
          onClick={prev}
          data-test-id="back"
        >
          <FormattedMessage {...buttonMessages.back} />
        </Button>
      );
    case "cancel":
      return (
        <Button
          variant="secondary"
          color="text"
          onClick={onClose}
          data-test-id="cancel"
        >
          <FormattedMessage {...buttonMessages.cancel} />
        </Button>
      );
    case "finish":
      return (
        <Button variant="primary" onClick={submit}>
          <FormattedMessage {...{ id: "2341s", defaultMessage: "Finish" }} />
        </Button>
      );
    default:
      return null;
  }
};
