import { Typography } from "@material-ui/core";
import { Box } from "@saleor/macaw-ui-next";
import React from "react";

import { useStyles } from "../styles";
import { SupplyTransactionType } from "../types";
import { localizePrice } from "../urls";

interface TotalCostSectionType {
  data: SupplyTransactionType;
}

export const TotalCostSection: React.FC<TotalCostSectionType> = ({ data }) => {
  const classes = useStyles();

  return (
    <Box paddingX={8} marginTop={16}>
      <Typography variant="h2">Total cost</Typography>
      <Box className={classes.flexColumn} marginTop={10}>
        <Box className={classes.spaceBetween}>
          <Typography variant="h4">Subtotal</Typography>
          <Box className={classes.moneyBox}>
            <Typography
              className={classes.subtitle}
              style={{ width: "50px", textAlign: "right" }}
            >
              CHF
            </Typography>
            <Typography variant="h4" style={{ width: "120px" }}>
              {localizePrice(data?.subtotalCost)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.spaceBetween}>
          <Typography variant="h4">Transportation</Typography>
          <Box className={classes.moneyBox}>
            <Typography
              className={classes.subtitle}
              style={{ width: "50px", textAlign: "right" }}
            >
              CHF
            </Typography>
            <Typography variant="h4" style={{ width: "120px" }}>
              {localizePrice(data?.transportCost)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.spaceBetween}>
          <Typography variant="h4">Customs</Typography>
          <Box className={classes.moneyBox}>
            <Typography
              className={classes.subtitle}
              style={{ width: "50px", textAlign: "right" }}
            >
              CHF
            </Typography>
            <Typography variant="h4" style={{ width: "120px" }}>
              {localizePrice(data?.customsCost)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.spaceBetween}>
          <Typography variant="h4">Import VAT</Typography>
          <Box className={classes.moneyBox}>
            <Typography
              className={classes.subtitle}
              style={{ width: "50px", textAlign: "right" }}
            >
              CHF
            </Typography>
            <Typography variant="h4" style={{ width: "120px" }}>
              {localizePrice(data?.vatCost)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.spaceBetween} style={{ marginTop: "24px" }}>
          <Typography variant="h4">Total</Typography>
          <Box className={classes.moneyBox}>
            <Typography
              className={classes.subtitle}
              style={{ width: "50px", textAlign: "right" }}
            >
              CHF
            </Typography>
            <Typography variant="h4" style={{ width: "120px" }}>
              {localizePrice(data?.totalCost)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
