import { useQuery } from "@apollo/client";
import { WindowTitle } from "@dashboard/components/WindowTitle";
import useListSettings from "@dashboard/hooks/useListSettings";
import useNavigator from "@dashboard/hooks/useNavigator";
import { usePaginationReset } from "@dashboard/hooks/usePaginationReset";
import usePaginator, {
  createPaginationState,
  PaginatorContext,
} from "@dashboard/hooks/usePaginator";
import { sectionNames } from "@dashboard/intl";
import SupplierListPage from "@dashboard/suppliers/components/SupplierListPage/SupplierListPage";
import { suppliersList } from "@dashboard/suppliers/queries";
import { Suppliers } from "@dashboard/suppliers/types";
import {
  supplierListUrl,
  SupplierListUrlQueryParams,
  suppliersListPath,
} from "@dashboard/suppliers/urls";
import { ListViews } from "@dashboard/types";
import createSortHandler from "@dashboard/utils/handlers/sortHandler";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import { getSortParams } from "@dashboard/utils/sort";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";

const numberOfRows = [10, 20, 30, 50, 100];

interface SupplierListProps {
  params: SupplierListUrlQueryParams;
}

export const SuppliersList: React.FC<SupplierListProps> = ({ params }) => {
  const intl = useIntl();
  const navigate = useNavigator();
  const [noPerRows, setNoPerRows] = useState(0);

  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const sortAsc = urlParams.get("dir") || "DESC";
  // const sortBy = urlParams.get("sort");
  const searchParam = urlParams.get("search") || "";
  const after = urlParams.get("after") || undefined;
  const before = urlParams.get("before") || undefined;
  const last = before ? noPerRows : undefined;
  const first = after ? noPerRows : undefined;

  const { updateListSettings, settings } = useListSettings(
    ListViews.SUPPLIER_LIST,
  );

  usePaginationReset(supplierListUrl, params, settings.rowNumber);

  const paginationState = createPaginationState(settings.rowNumber, params);

  // const queryVariables = React.useMemo(
  //   () => ({
  //     ...paginationState,
  //     // filter: getFilterVariables(params),
  //     sort: getSortQueryVariables(params),
  //   }),
  //   [params, settings.rowNumber],
  // );

  const { data, loading, refetch } = useQuery(suppliersList, {
    variables: {
      first: !after && !before ? noPerRows : first,
      sortBy: { direction: sortAsc },
      filter: { search: searchParam },
      after,
      before,
      last,
    },
  });

  useEffect(() => {
    if (noPerRows === 0) {
      const localNoPerRows = localStorage.getItem("suppliersRows")
        ? parseInt(localStorage.getItem("suppliersRows"), 10)
        : 20;
      setNoPerRows(localNoPerRows);
    }
  }, []);

  const onSearchChange = (value: string) => {
    const params = new URLSearchParams(search);

    if (value) {
      params.set("search", value);
      params.delete("after");
      params.delete("before");
    } else {
      params.delete("search");
    }

    history.replace(`${suppliersListPath}?${params.toString()}`);
  };

  const onChangeRows = (value: number) => {
    const params = new URLSearchParams(search);

    if (urlParams.get("after") || urlParams.get("before")) {
      params.delete("after");
      params.delete("before");
    }

    localStorage.setItem("offerRows", value.toString());
    setNoPerRows(value);

    history.replace(`${suppliersListPath}?${params.toString()}`);
  };

  const paginationPrev = () => {
    const params = new URLSearchParams(search);
    const prevPageId = data.suppliers.pageInfo.startCursor;

    if (urlParams.get("after")) {
      params.delete("after");
    }

    params.set("before", prevPageId);

    history.replace(`${suppliersListPath}?${params.toString()}`);
  };

  const paginationNext = () => {
    const params = new URLSearchParams(search);
    const nextPageId = data.suppliers.pageInfo.endCursor;

    if (urlParams.get("before")) {
      params.delete("before");
    }

    params.set("after", nextPageId);

    history.replace(`${suppliersListPath}?${params.toString()}`);
  };

  // const { data, loading, refetch } = useListSuppliersQuery({
  //   displayLoader: true,

  // });

  const paginationValues = usePaginator({
    pageInfo: data?.suppliers?.pageInfo,
    paginationState,
    queryString: params,
  });
  const suppliers = mapEdgesToItems(data?.suppliers);
  const handleSort = createSortHandler(navigate, supplierListUrl, params);

  return (
    <PaginatorContext.Provider value={paginationValues}>
      <WindowTitle title={intl.formatMessage(sectionNames.suppliers)} />
      <SupplierListPage
        onSort={handleSort}
        sort={getSortParams(params)}
        suppliers={suppliers as Suppliers}
        disabled={loading}
        loading={loading}
        settings={settings}
        onUpdateListSettings={updateListSettings}
        onRefetch={async () => await refetch()}
        initialSearch={searchParam}
        onSearchChange={value => onSearchChange(value)}
        noPerRows={noPerRows}
        paginationNext={paginationNext}
        paginationPrev={paginationPrev}
        numberOfRows={numberOfRows}
        onChangeRows={value => onChangeRows(value)}
        paginationData={data}
      />
    </PaginatorContext.Provider>
  );
};

export default SuppliersList;
