import ControlledCheckbox from "@dashboard/components/ControlledCheckbox";
import Hr from "@dashboard/components/Hr";
import { Customer } from "@dashboard/customers/types";
import { ListCustomersQuery } from "@dashboard/graphql";
import { FormChange } from "@dashboard/hooks/useForm";
import Label from "@dashboard/orders/components/OrderHistory/Label";
import { ExcludedReportData } from "@dashboard/reports";
import ChannelDots from "@dashboard/reports/components/ChannelDots/ChannelDots";
import { SelectedProps } from "@dashboard/reports/components/ReportCreateDialog/ReportCreateDialog";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import {
  CircularProgress,
  List,
  ListItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { PaginationActions } from "@saleor/macaw-ui";
import { Box } from "@saleor/macaw-ui-next";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";

interface CustomersViewProps {
  data: ListCustomersQuery | undefined;
  loading: boolean;
  handleSearchChange: (query: string) => void;
  initialSearch: string;
  selectedCustomersIds: SelectedProps;
  setSelectedCustomersIds: React.Dispatch<React.SetStateAction<SelectedProps>>;
  handleFormData: FormChange;
  pagination: {
    next: () => void;
    prev: () => void;
  };
  excluded: ExcludedReportData;
  setExcluded: React.Dispatch<React.SetStateAction<ExcludedReportData>>;
}

export const Customers: React.FC<CustomersViewProps> = ({
  data,
  loading,
  handleSearchChange,
  initialSearch,
  selectedCustomersIds,
  setSelectedCustomersIds,
  handleFormData,
  pagination,
  excluded,
  setExcluded,
}) => {
  const intl = useIntl();
  const customers = mapEdgesToItems(data?.customers);
  const isAllSelected = selectedCustomersIds === "all";
  // !!customers?.length && selectedCustomersIds.length === customers?.length;

  const handleSelectedCustomers = (customer: Customer) => {
    if (!isAllSelected) {
      const selected =
        typeof selectedCustomersIds === "string" ? [] : selectedCustomersIds;
      const newSelectedCustomers = selected.includes(customer.id)
        ? [...selected.filter(id => id !== customer.id)]
        : [...selected, customer.id];

      setSelectedCustomersIds(newSelectedCustomers);
    } else {
      const newExcludedCustomers = excluded.customers.includes(customer.id)
        ? [...excluded.customers.filter(id => id !== customer.id)]
        : [...excluded.customers, customer.id];
      setExcluded({ ...excluded, customers: newExcludedCustomers });
    }
  };

  const handleSelectAllCustomers = () => {
    if (!customers) return;
    setSelectedCustomersIds(isAllSelected ? [] : "all");
    setExcluded({ ...excluded, customers: [] });
  };

  useEffect(() => {
    handleFormData({
      target: { name: "customers", value: selectedCustomersIds },
    });
  }, [selectedCustomersIds]);

  useEffect(() => {
    handleFormData({
      target: { name: "excluded", value: excluded },
    });
  }, [excluded]);

  return (
    <>
      <Box paddingY={6}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {!!handleSelectAllCustomers && (
            <ControlledCheckbox
              checked={isAllSelected}
              name="allChannels"
              label={
                <Label
                  text={intl.formatMessage({
                    id: "TZYZv9",
                    defaultMessage: "Select All Customers",
                  })}
                />
              }
              onChange={handleSelectAllCustomers}
            />
          )}

          {data?.customers && (
            <Typography>
              Selected:{" "}
              {isAllSelected
                ? isAllSelected &&
                  !excluded.customers.length &&
                  data.customers.totalCount
                  ? data.customers.totalCount
                  : data.customers.totalCount! - excluded.customers.length
                : selectedCustomersIds.length}{" "}
              / {data.customers.totalCount}
            </Typography>
          )}
        </Box>
        <TextField
          name="query"
          value={initialSearch}
          onChange={e => handleSearchChange(e.target.value)}
          label={intl.formatMessage({
            id: "kdRcqU",
            defaultMessage: "Search customers...",
          })}
          placeholder={intl.formatMessage({
            id: "kdRcqU",
            defaultMessage: "Search customers...",
          })}
          fullWidth
        />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" marginY={9}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Hr />
          <Box maxHeight={48} overflowY={"auto"}>
            <List>
              {customers?.map(item => {
                return (
                  <ListItem key={item.id} divider disableGutters>
                    <ControlledCheckbox
                      checked={
                        (isAllSelected &&
                          !excluded.customers.includes(item.id)) ||
                        selectedCustomersIds.includes(item.id)
                      }
                      name={item.id}
                      label={
                        <Box display={"flex"}>
                          <Typography>{item.email}</Typography>
                          <ChannelDots
                            channels={
                              item.metafields.channel
                                ? [item.metafields.channel]
                                : null
                            }
                          />
                        </Box>
                      }
                      onChange={() => handleSelectedCustomers(item)}
                    />
                  </ListItem>
                );
              })}
            </List>
            {data?.customers && (
              <Box
                paddingTop={7}
                paddingBottom={2}
                display={"flex"}
                justifyContent={"center"}
              >
                <PaginationActions
                  hasNextPage={data.customers.pageInfo.hasNextPage}
                  hasPreviousPage={data.customers.pageInfo.hasPreviousPage}
                  onNextPage={pagination.next}
                  onPreviousPage={pagination.prev}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};
