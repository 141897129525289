import { useMutation } from "@apollo/client";
import ActionDialog from "@dashboard/components/ActionDialog";
import { Typography } from "@material-ui/core";
import { Box, Input } from "@saleor/macaw-ui-next";
import React, { useEffect, useState } from "react";

import { updateSupplyTransactionLine } from "../mutations";
import { useStyles } from "../styles";
import { EditProductType } from "../types";

interface EditProductDialogProps {
  onClose: () => void;
  open: boolean;
  lineData: EditProductType;
  onSuccess: () => void;
}

export const EditProductDialog: React.FC<EditProductDialogProps> = ({
  onClose,
  open,
  lineData,
  onSuccess,
}) => {
  const [updateLine] = useMutation(updateSupplyTransactionLine);
  const [quantity, setQuantity] = useState<number>(0);
  const [quantityError, setQuantityError] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [unitPriceError, setUnitPriceError] = useState("");

  const classes = useStyles();

  useEffect(() => {
    if (lineData.quantity !== 0) {
      setQuantity(lineData.quantity);
    }
  }, [lineData.quantity, lineData.id]);

  useEffect(() => {
    if (lineData.unitPrice) {
      setUnitPrice(lineData.unitPrice.toString());
    }
  }, [lineData.unitPrice, lineData.id]);

  const onUpdate = async () => {
    if (!quantity) {
      return setQuantityError("This field is required.");
    } else {
      setQuantityError("");
    }

    if (!unitPrice) {
      return setUnitPriceError("This field is required.");
    } else {
      setUnitPriceError("");
    }

    try {
      await updateLine({
        variables: {
          id: lineData.id,
          input: { quantity, unitPrice: parseFloat(unitPrice) },
        },
      });

      onSuccess();
      setQuantityError("");
      setUnitPriceError("");
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onUpdate()}
      title="Edit Product"
      confirmButtonState="default"
      maxWidth="sm"
      confirmButtonLabel="Save"
      backButtonText="Cancel"
    >
      <Box gap={6} flexDirection="column" display="flex">
        <Box>
          <Input
            label="Quantity"
            size="medium"
            value={quantity}
            onChange={e => setQuantity(parseInt(e.target.value))}
            name="productQuantity"
            type="number"
            className={classes.input}
          />
          {quantityError && (
            <Typography variant="body2" style={{ color: "red" }}>
              {quantityError}
            </Typography>
          )}
        </Box>
        <Box>
          <Input
            label="Single price"
            size="medium"
            value={unitPrice}
            onChange={e => setUnitPrice(e.target.value)}
            name="productUnitPrice"
            type="number"
            className={classes.input}
          />
          {unitPriceError && (
            <Typography variant="body2" style={{ color: "red" }}>
              {unitPriceError}
            </Typography>
          )}
        </Box>
      </Box>
    </ActionDialog>
  );
};
