import { TopNav } from "@dashboard/components/AppLayout";
import { Button } from "@saleor/macaw-ui";
import { Box, ChevronRightIcon, Text } from "@saleor/macaw-ui-next";
import React from "react";

interface HeaderNavProps {
  onCreate: () => void;
}

export const HeaderNav: React.FC<HeaderNavProps> = ({ onCreate }) => {
  return (
    <TopNav title={"Imports"} withoutBorder isAlignToRight={false}>
      <Box
        __flex={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          <Box marginX={5} display="flex" alignItems="center">
            <ChevronRightIcon />
          </Box>
          <Text variant="title" size="small">
            All Imports
          </Text>
        </Box>
      </Box>
      <Box>
        <Button onClick={onCreate} type="button" variant="primary">
          Create Import
        </Button>
      </Box>
    </TopNav>
  );
};
