import ActionDialog from "@dashboard/components/ActionDialog";
import {Box} from "@saleor/macaw-ui-next";
import React from "react";

import {DeleteProductType} from "../types";
import {Typography} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {supplyTransactionLineDelete} from "@dashboard/imports/mutations";
import useNotifier from "@dashboard/hooks/useNotifier";

interface DeleteProductDialogProps {
  onClose: () => void;
  open: boolean;
  lineData: DeleteProductType;
  onSuccess: () => void;
}

export const DeleteProductDialog: React.FC<DeleteProductDialogProps> = ({
  onClose,
  open,
  lineData,
  onSuccess
}) => {
  const [deleteMutation] = useMutation(supplyTransactionLineDelete);

  const notify = useNotifier();

  const onDelete = async () => {
    try {
      const { data } = await deleteMutation({
        variables: {
          id: lineData.id,
        }
      })

      if(data?.supplyTransactionLineDelete?.errors?.length === 0) {
        onClose()
        onSuccess()
        notify({
          status: "success",
          text: `${lineData.name} has been deleted successfully.`,
        });
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onDelete()}
      title="Delete product"
      confirmButtonState="default"
      maxWidth="sm"
      confirmButtonLabel="Confirm"
      backButtonText="Cancel"
    >
      <Box gap={6} flexDirection="column" display="flex">
        <Typography variant="subtitle1">
          Are you sure you want to delete <b>{lineData.name}</b> from the product list?
        </Typography>
      </Box>
    </ActionDialog>
  );
};
