import { Button } from "@dashboard/components/Button";
import { FormChange } from "@dashboard/hooks/useForm";
import { getDates } from "@dashboard/reports/components/ReportCreateDialog/steps/util";
import { Box, Input } from "@saleor/macaw-ui-next";
import React, { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const datesMessages = defineMessages({
  today: {
    id: "zWgbGg",
    defaultMessage: "Today",
  },
  yesterday: {
    id: "6dIxDP",
    defaultMessage: "Yesterday",
  },
  thisMonth: {
    id: "J3fNDZ",
    defaultMessage: "This month",
  },
  lastMonth: {
    id: "y9ZbeM",
    defaultMessage: "Last month",
  },
  thisYear: {
    id: "lPShsT",
    defaultMessage: "This year",
  },
  lastYear: {
    id: "4RJ4Im",
    defaultMessage: "Last year",
  },
});

interface ReportDateProps {
  onChange: FormChange;
  selected: {
    startDate: string;
    endDate: string;
  };
}

const ReportDate: React.FC<ReportDateProps> = ({ onChange, selected }) => {
  const [startDate, setStartDate] = useState(
    selected.startDate || getDates.today,
  );
  const [endDate, setEndDate] = useState(selected.endDate || getDates.today);

  useEffect(() => {
    onChange({
      target: {
        name: "dates",
        value: {
          endDate,
          startDate,
        },
      },
    });
  }, [startDate]);

  useEffect(() => {
    onChange({
      target: {
        name: "dates",
        value: {
          startDate,
          endDate,
        },
      },
    });
  }, [endDate]);

  const setDates = (firstDay: string, lastDay: string) => {
    setStartDate(firstDay);
    setEndDate(lastDay);
  };

  const handleToday = () => setDates(getDates.today, getDates.today);

  const handleYesterday = () =>
    setDates(getDates.yesterday, getDates.yesterday);

  const handleThisMonth = () =>
    setDates(getDates.thisMonth.firstDay, getDates.thisMonth.lastDay);

  const handleLastMonth = () =>
    setDates(getDates.previousMonth.firstDay, getDates.previousMonth.lastDay);

  const handleThisYear = () => {
    setDates(getDates.thisYear.firstDay, getDates.thisYear.lastDay);
  };

  const handlePreviousYear = () => {
    setDates(getDates.previousYear.firstDay, getDates.previousYear.lastDay);
  };

  return (
    <>
      <Box
        display="grid"
        __gridTemplateColumns="repeat(2, 1fr)"
        gap={5}
        marginBottom={5}
      >
        <Input
          type="date"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <Input
          type="date"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
      </Box>

      <Box
        marginTop={10}
        display="grid"
        __gridTemplateColumns="repeat(2, 1fr)"
        gap={5}
      >
        <Button variant="tertiary" onClick={handleToday}>
          <FormattedMessage {...datesMessages.today} />
        </Button>
        <Button variant="tertiary" onClick={handleYesterday}>
          <FormattedMessage {...datesMessages.yesterday} />
        </Button>
        <Button variant="tertiary" onClick={handleThisMonth}>
          <FormattedMessage {...datesMessages.thisMonth} />
        </Button>
        <Button variant="tertiary" onClick={handleLastMonth}>
          <FormattedMessage {...datesMessages.lastMonth} />
        </Button>
        <Button variant="tertiary" onClick={handleThisYear}>
          <FormattedMessage {...datesMessages.thisYear} />
        </Button>
        <Button variant="tertiary" onClick={handlePreviousYear}>
          <FormattedMessage {...datesMessages.lastYear} />
        </Button>
      </Box>
    </>
  );
};

export default ReportDate;
