import { Select, Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import React, { FC } from "react";

interface ImportPaginationProps {
  noPerRows: number;
  importData: any;
  classes: string | any;
  numberOfRows: number[];
  onChangeRows: (value: number) => void;
  paginationPrev: () => void;
  paginationNext: () => void;
}

export const ImportPagination: FC<ImportPaginationProps> = ({
  onChangeRows,
  paginationPrev,
  paginationNext,
  noPerRows,
  importData,
  classes,
  numberOfRows,
}) => (
  <div className={classes.pagination}>
    <div className={classes.flexRowWithGap}>
      <Typography variant="body2">No. of rows</Typography>
      <Select
        data-test-id="row-number-select"
        value={noPerRows}
        onChange={event => onChangeRows(event.target.value as number)}
      >
        {numberOfRows.map(number => (
          <MenuItem value={number} key={number} data-test-id="rowNumberOption">
            {number}
          </MenuItem>
        ))}
      </Select>

      <div className={classes.flexRowWithGap} style={{ marginLeft: "16px" }}>
        <Typography variant="body2">
          {importData.supplyTransactions.totalCount}
        </Typography>
        <Typography variant="body2">Imports</Typography>
      </div>
    </div>
    <div className={classes.flexRowWithGap}>
      <ButtonBase
        disableRipple
        data-test="button-pagination-back"
        aria-label="previous page"
        className={clsx(
          classes.arrow,
          !importData.supplyTransactions.pageInfo.hasPreviousPage &&
            classes.btn_disabled,
        )}
        onClick={() => paginationPrev()}
        disabled={!importData.supplyTransactions.pageInfo.hasPreviousPage}
      >
        <ChevronLeft style={{ width: "16px" }} />
      </ButtonBase>

      <ButtonBase
        disableRipple
        data-test="button-pagination-next"
        aria-label="next page"
        className={clsx(
          classes.arrow,
          !importData.supplyTransactions.pageInfo.hasNextPage &&
            classes.btn_disabled,
        )}
        onClick={() => paginationNext()}
        disabled={!importData.supplyTransactions.pageInfo.hasNextPage}
      >
        <ChevronRight style={{ width: "16px" }} />
      </ButtonBase>
    </div>
  </div>
);
