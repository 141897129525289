import TableCellHeader from "@dashboard/components/TableCellHeader";
import TableRowLink from "@dashboard/components/TableRowLink";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { DeleteIcon, ResponsiveTable} from "@saleor/macaw-ui";
import { Box, Button } from "@saleor/macaw-ui-next";
import React, {useState} from "react";
import { useIntl } from "react-intl";
import Moment from "react-moment";

import { useStyles } from "../styles";
import { ImportsType } from "../types";
import { importPath, localizePrice } from "../urls";
import {DeleteImportDialog} from "@dashboard/imports/components/DeleteImportDialog";

interface ImportListProps {
  imports: ImportsType[];
  onDeleteSuccess: () => void;
}

export const ImportsList: React.FC<ImportListProps> = ({ imports, onDeleteSuccess }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [deleteLineData, setDeleteLineData] = useState<any>({
    id: "",
    name: "",
    isImported: false
  });

  if (imports?.length === 0) {
    return (
      <div className={classes.centerFlex}>
        <Typography variant="h3">There are no imports</Typography>
      </div>
    );
  }

  return (
    <>
      <ResponsiveTable>
        <TableHead>
          <TableRow>
            <TableCellHeader width={100}>
              {intl.formatMessage({
                id: "GzL9oO",
                defaultMessage: "Number",
                description: "Import number",
              })}
            </TableCellHeader>
            <TableCellHeader width={350}>
              {intl.formatMessage({
                id: "mH3Qq+",
                defaultMessage: "Date",
                description: "Import Date",
              })}
            </TableCellHeader>
            <TableCellHeader>
              {intl.formatMessage({
                id: "G7Culn",
                defaultMessage: "Transport Company",
                description: "Import Transport Company",
              })}
            </TableCellHeader>
            <TableCellHeader width={200}>
              {intl.formatMessage({
                id: "vuLXMM",
                defaultMessage: "Quantity",
                description: "Import Quantity",
              })}
            </TableCellHeader>
            <TableCellHeader width={300}>
              {intl.formatMessage({
                id: "zA/3l2",
                defaultMessage: "Total",
                description: "Import total",
              })}
            </TableCellHeader>
            <TableCellHeader/>
          </TableRow>
        </TableHead>
        <TableBody>
          {imports?.map(item => {
            const { id, supplier, totalCost, createdAt, number, totalQuantity, isImported } =
                item.node;
            return (
                <TableRowLink
                    key={id}
                    href={importPath(id)}
                    hover={!!id}
                    linkClassName={classes.pointer}
                >
                  <TableCell>#{number}</TableCell>
                  <TableCell>
                    <Box className={classes.spaceBetween}>
                      <Moment date={createdAt} format="dddd, MMMM Do, YYYY" />
                      <Moment
                          date={createdAt}
                          format="h:mm A"
                          style={{ color: "#949494", fontWeight: "bolder" }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>{supplier.companyName}</TableCell>
                  <TableCell>{totalQuantity}</TableCell>
                  <TableCell>
                    <Box className={classes.flexRow}>
                      <Typography className={classes.subtitle}>CHF</Typography>
                      <Typography variant="h4">
                        {localizePrice(totalCost)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.flexEnd}>
                    <Button
                      variant="tertiary"
                      icon={<DeleteIcon />}
                      onClick={(e) => {
                        e.preventDefault();
                        setDeleteLineData({id, name: supplier.companyName, isImported })
                      }}
                    />
                  </TableCell>
                </TableRowLink>
            );
          })}
        </TableBody>
      </ResponsiveTable>
      {deleteLineData.id && (
          <DeleteImportDialog
              onClose={() => setDeleteLineData({ id: '', name: ''})}
              open={!!deleteLineData.id}
              importData={deleteLineData}
              onSuccess={onDeleteSuccess}
          />
      )}
    </>
  );
};
