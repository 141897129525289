import { useMutation } from "@apollo/client";
import ActionDialog from "@dashboard/components/ActionDialog";
import SingleSelectField from "@dashboard/components/SingleSelectField";
import useNotifier from "@dashboard/hooks/useNotifier";
import { Input } from "@saleor/macaw-ui-next";
import React, { useEffect, useState } from "react";

import { supplierCreate, supplierUpdate } from "../mutations";
import { useStyles } from "../style";
import { EditSupplierDataType } from "../types";
import { IndustryList } from "../urls";

interface SupplierModalProps {
  onClose: () => void;
  open: boolean;
  type: "add" | "edit";
  editData?: EditSupplierDataType;
  onRefetch: () => void;
}

export const SupplierModal: React.FC<SupplierModalProps> = ({
  onClose,
  open,
  editData,
  type,
  onRefetch,
}) => {
  const [industry, setIndustry] = useState<string | undefined>(undefined);
  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState<number>();
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [note, setNote] = useState("");

  const [createMutation] = useMutation(supplierCreate);
  const [updateMutation] = useMutation(supplierUpdate);

  const classes = useStyles();
  const notify = useNotifier();

  useEffect(() => {
    if (type === "edit" && editData) {
      setCompanyName(editData.companyName);
      setFirstName(editData.firstName);
      setLastName(editData.lastName);
      setEmail(editData.email);
      setAddress(editData.address);
      setPostalCode(editData.postalCode);
      setCity(editData.city);
      setCountry(editData.country);
      setNote(editData.note);

      if (editData.industry) {
        onSelectIndustry(editData.industry);
      }
    }
  }, [editData?.id]);

  const onSelectIndustry = (id: string) => {
    setIndustry(id);
  };

  const onSave = async () => {
    const payload = {
      industry,
      companyName,
      firstName,
      lastName,
      email,
      address,
      postalCode,
      city,
      country,
      note,
    };

    if (type === "edit") {
      try {
        const { data } = await updateMutation({
          variables: {
            id: editData?.id,
            input: payload,
          },
        });

        if (data?.supplierUpdate?.errors?.length === 0) {
          notify({
            status: "success",
            text: `${companyName} has been updated successfully.`,
          });
        }
        onClose();
        onRefetch();
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const { data } = await createMutation({
          variables: {
            input: payload,
          },
        });

        if (data?.supplierCreate?.errors?.length === 0) {
          notify({
            status: "success",
            text: `${companyName} has been created successfully.`,
          });
        }

        onClose();
        onRefetch();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onSave()}
      title={`${type === "add" ? "Add" : "Edit"} Supplier`}
      confirmButtonState="default"
      maxWidth="sm"
      confirmButtonLabel={type === "add" ? "Create" : "Save"}
      backButtonText="Cancel"
      disabled={!companyName || !industry}
    >
      <div className={classes.scrollModal}>
        <Input
          label="Company name"
          size="medium"
          value={companyName}
          onChange={e => setCompanyName(e.target.value)}
          name="companyName"
          type="text"
        />

        <div className={classes.select}>
          <SingleSelectField
            choices={IndustryList}
            name="channels"
            label="Industry"
            value={industry}
            onChange={e => onSelectIndustry(e.target.value)}
          />
        </div>

        <Input
          label="First name"
          size="medium"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          name="firstName"
          type="text"
        />
        <Input
          label="Last name"
          size="medium"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          name="lastName"
          type="text"
        />
        <Input
          label="Email"
          size="medium"
          value={email}
          onChange={e => setEmail(e.target.value)}
          name="email"
          type="text"
        />
        <Input
          label="Address"
          size="medium"
          value={address}
          onChange={e => setAddress(e.target.value)}
          name="address"
          type="text"
        />
        <Input
          label="Postal code"
          size="medium"
          value={postalCode}
          onChange={e => setPostalCode(parseInt(e.target.value))}
          name="postalCode"
          type="number"
          inputMode="numeric"
        />
        <Input
          label="City"
          size="medium"
          value={city}
          onChange={e => setCity(e.target.value)}
          name="city"
          type="text"
        />
        <Input
          label="Country"
          size="medium"
          value={country}
          onChange={e => setCountry(e.target.value)}
          name="country"
          type="text"
        />
        <Input
          label="Note"
          size="medium"
          value={note}
          onChange={e => setNote(e.target.value)}
          name="note"
          type="text"
        />
      </div>
    </ActionDialog>
  );
};
