import { WindowTitle } from "@dashboard/components/WindowTitle";
import { asSortParams } from "@dashboard/utils/sort";
import { parse as parseQs } from "qs";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import {
  SupplierListUrlQueryParams,
  SupplierListUrlSortField,
  suppliersListPath,
} from "./urls";
import SuppliersListComponent from "./views/SuppliersList";

const SuppliersListView: React.FC<RouteComponentProps<{}>> = () => {
  const qs = parseQs(location.search.substr(1)) as any;
  const params: SupplierListUrlQueryParams = asSortParams(
    qs,
    SupplierListUrlSortField,
  );
  return <SuppliersListComponent params={params} />;
};

export const SuppliersSection: React.FC<{}> = () => {
  return (
    <>
      <WindowTitle title={"Suppliers"} />
      <Switch>
        <Route exact path={suppliersListPath} component={SuppliersListView} />
      </Switch>
    </>
  );
};
