import ActionDialog from "@dashboard/components/ActionDialog";
import { Box } from "@saleor/macaw-ui-next";
import React, {useState} from "react";
import {Typography, Checkbox} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {supplyTransactionDelete} from "@dashboard/imports/mutations";
import useNotifier from "@dashboard/hooks/useNotifier";

interface DeleteImportDialogProps {
  onClose: () => void;
  open: boolean;
  importData: { id: string, name: string, isImported: boolean };
  onSuccess: () => void;
}

export const DeleteImportDialog: React.FC<DeleteImportDialogProps> = ({ onClose, open, importData, onSuccess}) => {
  const [isStockReverted, setIsStockReverted] = useState(true);
  const [deleteMutation] = useMutation(supplyTransactionDelete);

  const notify = useNotifier();

    const onDelete = async () => {
      try {
        const stock = importData.isImported ? isStockReverted : false
        const { data } = await deleteMutation({
          variables: {
            id: importData.id,
            revertStock: stock
          }
        })

        if(data?.supplyTransactionDelete?.errors?.length === 0) {
          onSuccess()
          notify({
            status: "success",
            text: `${importData.name} has been deleted successfully.`,
          });
        }

        if(data?.supplyTransactionDelete?.errors?.length !== 0) {
          notify({
            status: "error",
            text: data?.supplyTransactionDelete?.errors[0]?.message,
          });
        }
        
        onClose()

    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onDelete()}
      title="Delete Import"
      confirmButtonState="default"
      maxWidth="sm"
      confirmButtonLabel="Confirm"
      backButtonText="Cancel"
    >
      <Box gap={6} flexDirection="column" display="flex">
        <Typography variant="subtitle1">
          Are you sure you want to delete <b>{importData.name}</b> from the import list?
        </Typography>
        {importData.isImported && (
          <Box display="flex" alignItems="center">
            <Checkbox
              name='revertStock'
              onChange={() => setIsStockReverted(!isStockReverted)}
              checked={isStockReverted}
            />
            <Typography variant="body1">
              Revert stock
            </Typography>
          </Box>
        )}
      </Box>
    </ActionDialog>
  );
};
