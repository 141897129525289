import { useMutation } from "@apollo/client";
import ActionDialog from "@dashboard/components/ActionDialog";
import SingleAutocompleteSelectField from "@dashboard/components/SingleAutocompleteSelectField";
import { Typography } from "@material-ui/core";
import { Box, Input } from "@saleor/macaw-ui-next";
import React, { useEffect, useState } from "react";

import { createSupplyMutation } from "../mutations";
import { useStyles } from "../styles";

interface CreateImportModalProps {
  onClose: () => void;
  open: boolean;
  suppliersList: any;
  onSuccess: (id: string) => void;
  onSupplierSearch: (text: string) => void;
}

export const CreateImportModal: React.FC<CreateImportModalProps> = ({
  onClose,
  open,
  suppliersList,
  onSuccess,
  onSupplierSearch,
}) => {
  const [createSupply] = useMutation(createSupplyMutation);

  const [supplierChoiceId, setSupplierChoiceId] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [transportCost, setTransportCost] = useState("");
  const [customsCost, setCustomsCost] = useState("");
  const [vatCost, setVatCost] = useState("");

  const [supplierError, setSupplierError] = useState("");

  const [parsedSuppliers, setParsedSuppliers] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (suppliersList?.suppliers?.edges?.length !== 0) {
      const parseSuppliers = suppliersList?.suppliers?.edges.map(
        (supplier: any) => {
          return { label: supplier.node.companyName, value: supplier.node.id };
        },
      );
      setParsedSuppliers(parseSuppliers);
    }
  }, [suppliersList]);

  const onSelectSupplier = async (id: string) => {
    setSupplierChoiceId(id);
    const supplierLabel = parsedSuppliers.find(
      supplier => supplier.value === id,
    )?.label;
    setSupplierName(supplierLabel);
  };

  const resetState = () => {
    setSupplierChoiceId("");
    setSupplierName("");
    setTransportCost("");
    setCustomsCost("");
    setVatCost("");
  };

  const onCreate = async () => {
    try {
      const input = {
        supplier: supplierChoiceId,
        transportCost: parseFloat(transportCost),
        customsCost: parseFloat(customsCost),
        vatCost: parseFloat(vatCost),
      };
      const { data } = await createSupply({
        variables: { input },
      });

      if (data?.supplyTransactionCreate?.errors?.length !== 0) {
        const { errors } = data?.supplyTransactionCreate;
        if (errors[0].field === "supplier") {
          setSupplierError(errors[0].message);
        }
      }

      if (data?.supplyTransactionCreate?.errors?.length === 0) {
        onSuccess(data?.supplyTransactionCreate?.supplyTransaction?.id);
        resetState();
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ActionDialog
      onClose={onClose}
      open={open}
      onConfirm={() => onCreate()}
      title="Transport and Costs"
      confirmButtonState="default"
      maxWidth="sm"
      confirmButtonLabel="Create"
      backButtonText="Cancel"
    >
      <Box gap={6} flexDirection="column" display="flex">
        <Box>
          <SingleAutocompleteSelectField
            displayValue={supplierName}
            name="transportCompany"
            label={"Choose Transport Company"}
            choices={parsedSuppliers}
            value={supplierChoiceId}
            onChange={e => onSelectSupplier(e.target.value)}
            InputProps={{
              required: true,
            }}
            fetchChoices={value => onSupplierSearch(value)}
          />
          {supplierError && (
            <Typography variant="body2" style={{ color: "red" }}>
              {supplierError}
            </Typography>
          )}
        </Box>

        <Input
          label={"Transport Cost"}
          size="medium"
          value={transportCost}
          onChange={e => setTransportCost(e.target.value)}
          name="transportCost"
          type="number"
          inputMode="decimal"
          className={classes.input}
        />
        <Input
          label={"Customs"}
          size="medium"
          value={customsCost}
          onChange={e => setCustomsCost(e.target.value)}
          name="customsCost"
          type="number"
          className={classes.input}
          inputMode="decimal"
        />
        <Input
          label={"Import Vat"}
          size="medium"
          value={vatCost}
          onChange={e => setVatCost(e.target.value)}
          name="vatCost"
          type="number"
          inputMode="decimal"
          className={classes.input}
        />
      </Box>
    </ActionDialog>
  );
};
