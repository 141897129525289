import React, { FC, useEffect, useState } from 'react';
import { Typography } from "@material-ui/core";
import { Box } from '@saleor/macaw-ui-next';
import { Button, makeStyles } from '@saleor/macaw-ui';
import { useMutation } from '@apollo/client';
import { customerDiscount } from '@dashboard/customers/mutations';
import useNotifier from '@dashboard/hooks/useNotifier';
import TextWithSelectField from "@dashboard/components/TextWithSelectField";
import { mapSingleValueNodeToChoice } from '@dashboard/utils/maps';
import { ChangeEvent } from '@dashboard/hooks/useForm';

const useStyles = makeStyles(
  {
    input: {
      width: '70%',
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    box: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      maxHeight: "72px",
    },
    flex: {
      display: 'flex',
      gap: '8px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    button: {
      height: '40px',
    },
    selectWidth: {
      width: 130
    }

  },
  { name: "CustomerDiscount" },
);

type CustomerDiscountProps = {
  customerId: string;
  customerDiscountType: string;
  customerAmount: number;
}

const discountType = ["CHF", "%"]

export const CustomerDiscount: FC<CustomerDiscountProps> = ({ customerId, customerDiscountType, customerAmount }) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState("CHF");
  const [discountAmount, setDiscountAmount] = useState('')

  const [discountMutation] = useMutation(customerDiscount);

  const classes = useStyles();
  const notify = useNotifier();

  useEffect(() => {
    if(customerDiscountType) {
      const type = customerDiscountType === "FIXED" ? "CHF" : "%";
      setSelectedDiscountType(type);
    }

    if(customerAmount) {
      setDiscountAmount(customerAmount.toString())
    } 

  }, [customerAmount, customerDiscountType])

  const applyDiscount = async () => {
    const parsedAmount = parseInt(discountAmount);
    if(selectedDiscountType === '%' && parsedAmount > 100) {
      return notify({
        status: "warning",
        title: "Discount percentage cannot be greater then 100",
      })
    }
    try {
      const type = selectedDiscountType === "CHF" ? "FIXED" : "PERCENTAGE"
      const { data } = await discountMutation({
        variables: {
          id: customerId,
          amount: parsedAmount,
          discountType: type,
        }
      })

      if(data?.customerUpdate?.errors?.length === 0) {
        notify({
          status: "success",
          title: 'Discount applied successfully.',
        })
      }
      if(data?.customerUpdate?.errors?.length !== 0) {
        notify({
          status: "error",
          title: 'Sorry, something went wrong.',
        })
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleInputChange = (event: ChangeEvent<any>) => {
    if (event.target?.name === "selectedDiscountType") {
      return setSelectedDiscountType(event.target?.value);
    }

    if (event.target?.name === "discountAmount") {
      if(isNaN(event.target?.value)) {
        return setDiscountAmount('')
      }
      setDiscountAmount(event.target?.value.toString())
    }
  };

  return (
    <Box className={classes.column} padding={6}>
      <Typography variant='h4'>
        Customer discount
      </Typography>
      <Box className={classes.box}>
        <Box className={classes.flex}>
          <TextWithSelectField
            change={handleInputChange}
            choices={mapSingleValueNodeToChoice(discountType)}
            textFieldProps={{
              type: "number",
              label: "Amount",
              name: "discountAmount",
              value: discountAmount,
              minValue: 0,
            }}
            selectFieldProps={{
              name: "selectedDiscountType",
              value: selectedDiscountType,
              className: classes.selectWidth,
            }}
            containerClassName={classes.input}
          />
        </Box>
        <Button className={classes.button} onClick={() => applyDiscount()} disabled={!discountAmount}>
          Apply
        </Button>
      </Box>
    </Box>
    
  )
}