import { gql } from "@apollo/client";

export const createSupplyMutation = gql`
  mutation createSupplyTransaction($input: SupplyTransactionCreateInput!) {
    supplyTransactionCreate(input: $input) {
      supplyTransaction {
        id
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export const createSupplyTransactionLine = gql`
  mutation ApplyTransactionLine($input: SupplyTransactionLineCreateInput!) {
    supplyTransactionLineCreate(input: $input) {
      supplyTransactionLine {
        id
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export const updateSupplyTransactionLine = gql`
  mutation UpdateProductLine(
    $id: ID!
    $input: SupplyTransactionLineUpdateInput!
  ) {
    supplyTransactionLineUpdate(id: $id, input: $input) {
      errors {
        field
        message
        code
      }
    }
  }
`;

export const updateSupplyTransaction = gql`
  mutation supplyTransactionUpdate(
    $id: ID!
    $input: SupplyTransactionUpdateInput!
  ) {
    supplyTransactionUpdate(id: $id, input: $input) {
      errors {
        field
        code
        message
      }
    }
  }
`;

export const supplyTransactionImport = gql`
  mutation TransactionImport($id: ID!) {
    supplyTransactionImport(id: $id) {
      errors {
        field
        code
        message
      }
    }
  }
`;

export const supplyTransactionDelete = gql`
  mutation supplyDelete($id: ID, $revertStock: Boolean) {
    supplyTransactionDelete(id: $id, revertStocks: $revertStock) {
      errors {
        field
        message
        code
      }
    }
  }
`;

export const supplyTransactionLineDelete = gql`
  mutation supplyTransactionLineDelete($id: ID!) {
    supplyTransactionLineDelete(id: $id) {
      errors {
        field
        message
      }
    }
  }
`