import { useMutation } from "@apollo/client";
import useAppChannel from "@dashboard/components/AppLayout/AppChannelContext";
import { DEFAULT_INITIAL_SEARCH_DATA } from "@dashboard/config";
import { StockAvailability } from "@dashboard/graphql";
import useLocalStorage from "@dashboard/hooks/useLocalStorage";
import OrderProductAddDialog from "@dashboard/orders/components/OrderProductAddDialog";
import { useOrderVariantSearch } from "@dashboard/searches/useOrderVariantSearch";
import { mapEdgesToItems } from "@dashboard/utils/maps";
import React, { useState } from "react";

import { createSupplyTransactionLine } from "../mutations";

interface AddProductDialogProps {
  onClose: () => void;
  open: boolean;
  supplyId: string;
  onSuccess: () => void;
}

export const AddProductDialog: React.FC<AddProductDialogProps> = ({
  onClose,
  open,
  supplyId,
  onSuccess,
}) => {
  const [createLine] = useMutation(createSupplyTransactionLine);
  const [selectedChannel] = useLocalStorage("channel", "");
  const { availableChannels } = useAppChannel(false);
  const selectedChannelData = availableChannels?.find(
    channel => channel.id === selectedChannel,
  );

  const [isCreating, setIsCreating] = useState(false);

  const {
    loadMore,
    search: variantSearch,
    result: variantSearchOpts,
  } = useOrderVariantSearch({
    variables: {
      ...DEFAULT_INITIAL_SEARCH_DATA,
      channel: selectedChannelData.slug,
      // address: getVariantSearchAddress(order),
      isPublished: true,
      stockAvailability: StockAvailability.IN_STOCK,
    },
  });

  const onCreate = async (variants: any) => {
    setIsCreating(true);
    for (const variant of variants) {
      const input = {
        transaction: supplyId,
        productVariant: variant.id,
      };
      try {
        await createLine({
          variables: { input },
        });
      } catch (e) {
        console.error(`error on ${variant.name}`, e);
      } finally {
        setIsCreating(false);
      }
    }
    onClose();
    onSuccess();
  };

  return (
    <OrderProductAddDialog
      confirmButtonState={isCreating ? "loading" : "success"}
      errors={[]}
      loading={variantSearchOpts.loading}
      open={open}
      hasMore={variantSearchOpts.data?.search.pageInfo.hasNextPage}
      products={mapEdgesToItems(variantSearchOpts?.data?.search)}
      onClose={onClose}
      onFetch={variantSearch}
      onFetchMore={loadMore}
      channelName={selectedChannelData.name}
      onSubmit={variants => onCreate(variants)}
    />
  );
};
