import { gql } from "@apollo/client";

export const suppliersList = gql`
  query ListSuppliers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: SupplierFilterInput
    $sort: SupplierSortingInput
  ) {
    suppliers(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
      sortBy: $sort
    ) {
      edges {
        node {
          id
          firstName
          lastName
          industry
          email
          companyName
          address
          city
          country
          postalCode
          note
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
