import { Button } from "@dashboard/components/Button";
import { FormChange } from "@dashboard/hooks/useForm";
import { buttonMessages } from "@dashboard/intl";
import ReportType from "@dashboard/reports/components/ReportCreateDialog/steps/ReportType";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface TypeModal {
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  change: FormChange;
  selected: string;
}

const TypeModal: React.FC<TypeModal> = ({
  onClose,
  onConfirm,
  isOpen,
  change,
  selected,
}) => {
  const intl = useIntl();
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth="xs" fullWidth>
      <DialogTitle disableTypography>
        {intl.formatMessage({
          id: "sPnNhy",
          defaultMessage: "Select type of report",
        })}
      </DialogTitle>
      <DialogContent>
        <ReportType onChange={change} selected={selected} />
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          <FormattedMessage {...buttonMessages.cancel} />
        </Button>
        <Button variant="primary" onClick={onConfirm} disabled={!selected}>
          <FormattedMessage {...buttonMessages.confirm} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TypeModal;
