const parseDate = (value: any) => {
  const date = new Date(value);

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};

const getPreviousMonth = () => {
  const lastDay = new Date().setDate(0);
  const firstDay = new Date();

  firstDay.setDate(1);
  firstDay.setMonth(firstDay.getMonth() - 1);

  return {
    firstDay: parseDate(firstDay),
    lastDay: parseDate(lastDay),
  };
};

const getThisMonth = () => {
  const today = new Date();
  const firstDay = new Date().setDate(1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return {
    firstDay: parseDate(firstDay),
    lastDay: parseDate(lastDay),
  };
};

const getToday = () => {
  return parseDate(new Date());
};

const getYesterday = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return parseDate(currentDate);
};

const getThisYear = () => {
  const firstDay = new Date(new Date().getFullYear(), 0, 1);
  const lastDay = new Date(new Date().getFullYear(), 11, 31);
  return {
    firstDay: parseDate(firstDay),
    lastDay: parseDate(lastDay),
  };
};

const getPreviousYear = () => {
  const firstDay = new Date(new Date().getFullYear() - 1, 0, 1);
  const lastDay = new Date(new Date().getFullYear() - 1, 11, 31);
  return {
    firstDay: parseDate(firstDay),
    lastDay: parseDate(lastDay),
  };
};

export const getDates = {
  yesterday: getYesterday(),
  today: getToday(),
  thisMonth: getThisMonth(),
  previousMonth: getPreviousMonth(),
  thisYear: getThisYear(),
  previousYear: getPreviousYear(),
};
