import { Channel } from "@dashboard/channels/utils";
import ChannelsAvailabilityDialogChannelsList from "@dashboard/components/ChannelsAvailabilityDialogChannelsList/ChannelsAvailabilityDialogChannelsList";
import { ControlledCheckbox } from "@dashboard/components/ControlledCheckbox";
import Hr from "@dashboard/components/Hr";
import { FormChange } from "@dashboard/hooks/useForm";
import Label from "@dashboard/orders/components/OrderHistory/Label";
import { Box } from "@saleor/macaw-ui-next";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";

interface ChannelsViewProps {
  channels: Channel[];
  selectedChannels: string[];
  setSelectedChannels: React.Dispatch<React.SetStateAction<string[]>>;
  handleFormData: FormChange;
}

export const ChannelsView: React.FC<ChannelsViewProps> = ({
  channels,
  selectedChannels,
  setSelectedChannels,
  handleFormData,
}) => {
  const intl = useIntl();

  const handleToggleAllChannels = () => {
    if (!channels) return;
    const allChannelsIds = channels.map(item => item.id);
    setSelectedChannels(
      selectedChannels.length === channels?.length ? [] : allChannelsIds,
    );
  };

  const handleChannelSelect = (option: Channel) => {
    const channelsData = selectedChannels.includes(option.id)
      ? [...selectedChannels.filter((id: string) => id !== option.id)]
      : [...selectedChannels, option.id];
    setSelectedChannels(channelsData);
  };

  useEffect(() => {
    handleFormData({
      target: { name: "channels", value: selectedChannels },
    });
  }, [selectedChannels]);

  return (
    <>
      {/* <ChannelsAvailabilityDialogContentWrapper
      hasAnyChannelsToDisplay={!!channels.length}
      hasAllSelected={selectedChannels.length === channels.length}
      query={channelsQuery}
      onQueryChange={onChannelsQueryChange}
      toggleAll={handleSelectAllChannels}
    > */}

      <ControlledCheckbox
        checked={selectedChannels.length === channels.length}
        name="allChannels"
        label={
          <Label
            text={intl.formatMessage({
              id: "dFogeY",
              defaultMessage: "Select All Channels",
            })}
          />
        }
        onChange={handleToggleAllChannels}
      />
      <Box marginTop={6}>
        <Hr />
        <ChannelsAvailabilityDialogChannelsList
          channels={channels}
          isChannelSelected={option => selectedChannels.includes(option.id)}
          onChange={handleChannelSelect}
        />
      </Box>
      {/* </ChannelsAvailabilityDialogContentWrapper> */}
    </>
  );
};
