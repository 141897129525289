// @ts-strict-ignore
import SingleSelectField from "@dashboard/components/SingleSelectField";
import { FormChange } from "@dashboard/hooks/useForm";
import { mapNodeToChoice } from "@dashboard/utils/maps";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";

interface ReportTypeProps {
  onChange: FormChange;
  selected: string;
}

export const reportTypes = [
  {
    name: "Total sales with gross margin (grouped by order)",
    id: "1",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Total sales without gross margin (grouped by order)",
    id: "2",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Total sales with gross margin (grouped by customer)",
    id: "3",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Total sales without gross margin (grouped by customer)",
    id: "4",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Total monthly sales with gross margin (grouped monthly)",
    id: "5",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Total monthly sales without gross margin (grouped monthly)",
    id: "6",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Product sales (group by customer)",
    id: "7",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Bottle statistics (grouped by country & region)",
    id: "8",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Liter statistics (grouped by country & region)",
    id: "9",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Product statistics (grouped by sold & returned)",
    id: "10",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Ongoing inventory accounting",
    id: "11",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Warehouse accounting",
    id: "12",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Stocks (grouped by supplier)",
    id: "13",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Stocks (grouped by category)",
    id: "14",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Stocks (with inventory & price)",
    id: "15",
    steps: ["channels", "customers", "products", "dates"],
  },
  {
    name: "Top Customers",
    id: "16",
    steps: ["channels", "customers", "dates"],
  },
  {
    name: "Price List (sorted alphabetically)",
    id: "17",
    steps: ["channels", "products"],
  },
  {
    name: "Price list (sorted by price)",
    id: "18",
    steps: ["channels", "products"],
  },
];

const ReportType: React.FC<ReportTypeProps> = ({ onChange, selected }) => {
  const [selectedReportType, selectReportType] = React.useState(selected);
  const intl = useIntl();

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    selectReportType(target.value);
  };

  useEffect(() => {
    onChange({
      target: { name: "report_type", value: selectedReportType },
    });
  }, [selectedReportType]);

  return (
    <SingleSelectField
      choices={mapNodeToChoice(reportTypes)}
      onChange={handleChange}
      name="report-type"
      label={intl.formatMessage({
        id: "HDqA4C",
        defaultMessage: "Report Type",
      })}
      value={selectedReportType ?? ""}
    />
  );
};

ReportType.displayName = "ReportType";
export default ReportType;
