import { defineMessages } from "react-intl";

export const messages = defineMessages({
  empty: {
    id: "CzzFoj",
    defaultMessage: "No suppliers found",
  },
});

export const columnsMessages = defineMessages({
  name: {
    id: "CWModJ",
    defaultMessage: "Name",
    description: "column header",
  },
  email: {
    id: "MZZbQH",
    defaultMessage: "Email",
    description: "column header",
  },
  companyName: {
    id: "mj0RUG",
    defaultMessage: "Company name",
    description: "column header",
  },
  industry: {
    id: "8qNTnl",
    defaultMessage: "Industry",
    description: "column header",
  },
  address: {
    id: "12RdfT",
    defaultMessage: "Address",
    description: "column header",
  },
  postalCode: {
    id: "d2veDR",
    defaultMessage: "Postal code",
    description: "column header",
  },
  location: {
    id: "YGRj4T",
    defaultMessage: "Location",
    description: "column header",
  },
  note: {
    id: "lFN/Fi",
    defaultMessage: "Note",
    description: "column header",
  },
});
