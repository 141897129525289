import { useListCustomersQuery } from "@dashboard/graphql";
import { FormChange } from "@dashboard/hooks/useForm";
import { ExcludedReportData } from "@dashboard/reports";
import { Customers } from "@dashboard/reports/components/Customers/Customers";
import { SelectedProps } from "@dashboard/reports/components/ReportCreateDialog/ReportCreateDialog";
import React, { useState } from "react";

interface ReportCustomersProps {
  onChange: FormChange;
  channels: string[];
  selected: SelectedProps;
  excludedData: ExcludedReportData;
}

const ReportCustomers: React.FC<ReportCustomersProps> = ({
  onChange,
  channels,
  selected,
  excludedData,
}) => {
  const [selectedCustomersIds, setSelectedCustomersIds] =
    React.useState<SelectedProps>(selected);
  const [excluded, setExcluded] = useState<ExcludedReportData>(excludedData);

  const queryParams = {
    first: 20,
    filter: {
      channels,
      search: undefined,
    },
    sort: {
      direction: "ASC",
      field: "LAST_NAME",
    },
  } as any;

  const [params, setParams] = useState(queryParams);

  const { data, loading, refetch } = useListCustomersQuery({
    displayLoader: true,
    variables: params as any,
  });

  const handleNext = () => {
    setParams({
      ...params,
      after: data?.customers?.pageInfo.endCursor,
      first: 20,
      before: undefined,
      last: undefined,
    });
    refetch();
  };

  const handlePrev = () => {
    setParams({
      ...params,
      after: undefined,
      first: undefined,
      last: 20,
      before: data?.customers?.pageInfo.startCursor,
    });
    refetch();
  };

  const handleSearch = (e: string) => {
    setSelectedCustomersIds([]);
    setParams({
      ...params,
      filter: {
        ...params.filter,
        search: e,
      },
      after: undefined,
      before: undefined,
      first: 20,
      last: undefined,
    });
  };

  return (
    <>
      <Customers
        data={data}
        loading={loading}
        handleSearchChange={handleSearch}
        initialSearch={params?.filter.search || ""}
        selectedCustomersIds={selectedCustomersIds}
        setSelectedCustomersIds={setSelectedCustomersIds}
        handleFormData={onChange}
        excluded={excluded}
        setExcluded={setExcluded}
        pagination={{
          next: handleNext,
          prev: handlePrev,
        }}
      />
    </>
  );
};

export default ReportCustomers;
